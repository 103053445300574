import { useMemo, useState } from 'react'
import track from '../../Tracking'
import usePopupStore, { ActiveSite, UpsellPopupType } from './popupStore'
import PopupStore from './popupStore'
import AppStore from '../../AppStore'
import { getSentText, getUpsellProperties, getUpsellRequestType } from './utils'
import { getSortedCustomersWithActiveSites, stringToInt } from '../../utils'
import { requestUpsell } from '../../services/OngoingProjectsApiService'
import { classes, stylesheet } from 'typestyle'
import {
  Button,
  ButtonIcon,
  Gray10,
  Gray100,
  Gray110,
  Gray20,
  Green0,
  Green20,
  Green90,
  ISelectOptionProps,
  Red90,
  Select,
  TextArea,
  TextInput,
  type14Medium,
  type24Bold,
  White,
  type12MediumProperties
} from '@traceair/tds'
import { CheckedIcon, CloseIcon } from '@traceair/webapp-icons'
import { CustomerData, ProjectActivity } from '../../types'
import useAppStore from '../../AppStore'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'

function closeUpsellPopup() {
  PopupStore.getState().closePopup()

  AppStore.getState().actions.hideWindow()
}

export function UpsellPopup() {
  const activePopup = usePopupStore(state => state.activePopup)
  const activeSite = usePopupStore(state => state.activeSite)

  const [activeAccount, setActiveAccount] = useState<CustomerData | undefined>(undefined)
  const [selectedActiveSite, setSelectedActiveSite] = useState<ActiveSite | undefined>(activeSite)

  const customers = useAppStore(state => state.customers)
  const usefulCustomers = useMemo(() => getSortedCustomersWithActiveSites(customers), [customers])
  const accountSelectOptions: ISelectOptionProps[] = usefulCustomers.map(customer => ({
    value: customer.id,
    title: customer.name
  }))

  const siteSelectOptions: ISelectOptionProps[] = activeAccount
    ? activeAccount.sites
        .filter(site => site.projectActivity !== ProjectActivity.Archived)
        .map(site => ({
          value: site.id,
          title: site.name
        }))
        // sort alphabetically by site name with respect to numbers in names
        .sort((a, b) => a.title.localeCompare(b.title, 'en', { numeric: true }))
    : []

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [isRequestSent, setIsRequestSent] = useState(false)

  const [comment, setComment] = useState('')
  const [count, setCount] = useState('')

  const toShowAccountAndSiteSelects = activeSite === undefined
  const toShowCounter = activePopup === UpsellPopupType.RequestFlights || activePopup === UpsellPopupType.AddPanoramas
  const toShowDescription =
    activePopup && [UpsellPopupType.AddLotViewer, UpsellPopupType.AddSchedules].includes(activePopup)

  const isSubmitDisabled = toShowCounter && count.length === 0

  function handleCountChange(value: string) {
    const regex = /^[0-9]+$/

    if (regex.test(value) || value.length === 0) {
      setCount(value)
    }
  }

  async function handleSend() {
    track(`"Send Request" on ${activePopup} popup is clicked`)

    if (!selectedActiveSite || !activePopup) {
      return
    }

    const requestType = getUpsellRequestType(activePopup)

    try {
      const requestParams = {
        customerId: selectedActiveSite.customerId,
        siteId: selectedActiveSite.id,
        props: {
          type: requestType,
          data: {
            count: stringToInt(count),
            comment
          }
        }
      }

      setError('')
      setIsLoading(true)

      await requestUpsell(requestParams)

      setIsRequestSent(true)
    } catch (error: unknown) {
      // TODO: Add Sentry
      setError(`Request failed, please try again later`)
    } finally {
      setIsLoading(false)
    }
  }

  if (!activePopup) {
    return null
  }

  const properties = getUpsellProperties(activePopup)

  return (
    <div className={classes(css.root, isRequestSent && css.sentRoot)}>
      {!isRequestSent ? (
        <>
          <div className={css.header}>
            <ButtonIcon onClick={closeUpsellPopup} icon={<CloseIcon />} styleType='white' size='s' />
            <div className={css.iconContainer}>{properties.headerIcon}</div>
          </div>
          <div className={classes(css.title, type24Bold)}>{properties.title}</div>

          {!toShowAccountAndSiteSelects && (
            <div className={classes(css.subTitle, type14Medium)}>{activeSite?.name}</div>
          )}

          {toShowCounter && (
            <div className={css.counter}>
              <TextInput
                placeholder={properties.counterLabel}
                label={properties.counterLabel}
                value={count}
                onChange={handleCountChange}
                autoFocus={true}
              />
            </div>
          )}

          {toShowDescription && <div className={classes(css.description, type14Medium)}>{properties.description}</div>}

          {toShowAccountAndSiteSelects && (
            <>
              <div className={css.select}>
                <Select
                  options={accountSelectOptions}
                  label={t7e(i18nKeys.UpsellPopupFieldLabelAccount)}
                  onChange={accountId => {
                    setActiveAccount(usefulCustomers.find(customer => customer.id === accountId))
                  }}
                  value={activeAccount?.id}
                  placeholder={t7e(i18nKeys.UpsellPopupFieldPlaceholderAccount)}
                />
              </div>

              <div className={css.select}>
                <Select
                  options={siteSelectOptions}
                  label={t7e(i18nKeys.UpsellPopupFieldLabelSite)}
                  disabled={!activeAccount}
                  onChange={(siteId, siteName) => {
                    if (!activeAccount) {
                      return
                    }

                    setSelectedActiveSite({
                      id: siteId,
                      name: String(siteName),
                      customerId: activeAccount.id
                    })
                  }}
                  value={selectedActiveSite?.id}
                  placeholder={t7e(i18nKeys.UpsellPopupFieldPlaceholderSite)}
                />
              </div>
            </>
          )}

          <div className={css.comment}>
            <TextArea
              placeholder={t7e(i18nKeys.UpsellPopupFieldPlaceholderComment)}
              value={comment}
              onChange={setComment}
            />
          </div>

          <div className={css.footer}>
            {Boolean(error) && <span className={css.error}>{error}</span>}

            <Button onClick={closeUpsellPopup} text={t7e(i18nKeys.ButtonCancel)} styleType='secondary' />

            <Button
              onClick={handleSend}
              text={properties.submitTitle}
              styleType='primary'
              loading={isLoading}
              disabled={isSubmitDisabled}
              className={css.submitButton}
            />
          </div>
        </>
      ) : (
        <>
          <div className={css.sentClose}>
            <ButtonIcon onClick={closeUpsellPopup} icon={<CloseIcon />} styleType='white' size='s' />
          </div>
          <div className={css.circle}>
            <CheckedIcon color={Green90} size={32} />
          </div>
          <div className={classes(type24Bold, css.sentTitle)}>{t7e(i18nKeys.UpsellPopupSuccess)}</div>
          <div className={classes(type14Medium, css.sentText)}>
            {getSentText(activePopup, selectedActiveSite, count)}
          </div>
          <Button
            onClick={closeUpsellPopup}
            text={t7e(i18nKeys.ButtonClose)}
            styleType='secondary'
            className={css.sentButton}
          />
        </>
      )}
    </div>
  )
}

const css = stylesheet({
  root: {
    width: 440,
    padding: 24,
    borderRadius: 16,
    backgroundColor: White,
    boxSizing: 'border-box'
  },
  sentRoot: {
    height: 348,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 64,
    paddingRight: 64
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse'
  },
  iconContainer: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Gray10,
    borderRadius: 12,
    border: `1px solid ${Gray20}`
  },
  title: {
    marginTop: 12
  },
  subTitle: {
    marginTop: 2,
    color: Gray100
  },
  counter: {
    width: 192,
    marginTop: 12
  },
  description: {
    marginTop: 12,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: 16
  },
  select: {
    marginTop: 16
  },
  comment: {
    marginTop: 16
  },

  footer: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 8
  },
  submitButton: {
    minWidth: 120 // For consistent width when the button shows "in progress" icon-indicator
  },
  error: {
    color: Red90,
    ...type12MediumProperties
  },
  sentClose: {
    position: 'absolute',
    top: 24,
    right: 24
  },
  circle: {
    width: 48,
    height: 48,
    borderRadius: 48,
    boxSizing: 'border-box',
    border: `1px solid ${Green20}`,
    backgroundColor: Green0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  sentTitle: {
    marginTop: 8,
    fontSize: 20
  },
  sentText: {
    marginTop: 2,
    color: Gray110,
    textAlign: 'center',

    whiteSpace: 'pre-line'
  },
  sentButton: {
    width: 110,
    marginTop: 20
  }
})
