import * as React from 'react'
import { JWTToken } from '../../../jwt-utils'
import urljoin from 'url-join'
import { classes, style } from 'typestyle'
import Carousel from './carousel/Carousel'
import NoScanStub from '../../../noscan.png'
import PanoPreview from './carousel/PanoPreview'
import { Pano, Scan } from '../../../types'
import track from '../../../Tracking'
import useAppStore from '../../../AppStore'

type ScanPreviewImgProps = {
  scan?: Scan
  panoramas: Pano[]
  officeAppLink: string
}

const SitePreviewImg: React.FunctionComponent<ScanPreviewImgProps> = ({
  scan,
  panoramas,
  officeAppLink
}: ScanPreviewImgProps) => {
  // Make sure this token is light, - it's submitted as a query param. If the token and headers exceed 16KB,
  // the request will fail due to an nginx limit.
  const siteViewToken = useAppStore(state => state.siteViewToken)

  const scanPreviewPath = scan?.previewPath
  const hasScanPreview = scanPreviewPath && siteViewToken
  const imgSrc = hasScanPreview ? buildScanPreviewUrl(scanPreviewPath, siteViewToken) : NoScanStub
  const panoramasWithPreview = panoramas.filter(panorama => !!panorama.previewPath)

  return (
    <div
      className={classes('scan-preview-container', scanPreviewContainer)}
      onClick={() => {
        track('Scan Preview clicked')
        window.open(officeAppLink, '_blank')
      }}
    >
      <Carousel>
        <img
          crossOrigin='anonymous'
          className={hasScanPreview ? 'scan-preview' : 'no-scan-preview'}
          src={imgSrc}
          alt='Scan preview'
        />
        {panoramasWithPreview.length > 0 &&
          siteViewToken &&
          panoramasWithPreview.map(panorama => {
            return (
              <PanoPreview
                token={siteViewToken}
                previewPath={panorama.previewPath}
                doneDate={panorama.doneDate}
                key={panorama.doneDate}
              />
            )
          })}
      </Carousel>
    </div>
  )
}

function buildScanPreviewUrl(previewPath: string, token: JWTToken) {
  return urljoin(FOStorage.url, FOStorage.filesPathPrefix, previewPath, `?access_token=${token}`)
}

export default SitePreviewImg

const scanPreviewContainer = style({
  padding: 8,
  borderRadius: 8,
  overflow: 'hidden',
  position: 'relative',
  cursor: 'pointer',
  display: 'flex',
  height: '100%'
})
