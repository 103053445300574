import React, { useEffect } from 'react'
import { classes, media, style } from 'typestyle'
import { BlackOpacity60, Blue80, ButtonIcon, White } from '@traceair/tds'
import { MoreIcon } from '@traceair/webapp-icons'
import ContextMenu from './ContextMenu'
import { CustomerData, SiteData } from '../../../../types'
import track from '../../../../Tracking'
import { Breakpoint } from '../../../../responsive-breakpoints'

interface ContextMenuButtonProps {
  customer: CustomerData
  site: SiteData
}
const ContextMenuButton: React.FC<ContextMenuButtonProps> = ({ customer, site }) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = React.useState(false)

  // Event handler to close the context menu
  const handleClose = (e: MouseEvent) => {
    const el: HTMLDivElement | null = document.querySelector('.site-card-context-menu-container')

    if (!el || el.contains(e.target as Node)) {
      return
    }
    setIsContextMenuOpen(false)
  }

  // Set up event listeners for detecting outside clicks
  useEffect(() => {
    document.addEventListener('click', handleClose)
    return () => {
      document.removeEventListener('click', handleClose)
    }
  }, [isContextMenuOpen])

  return (
    <div
      className={style({
        position: 'relative'
      })}
    >
      <ButtonIcon
        className={classes('site-card-context-menu-button', contextMenuButtonStyle(isContextMenuOpen))}
        size='xs'
        icon={<MoreIcon color={White} />}
        onClick={e => {
          track('Context menu button Click')
          e?.stopPropagation()
          setIsContextMenuOpen(!isContextMenuOpen)
        }}
      />
      {isContextMenuOpen && <ContextMenu site={site} customer={customer} />}
      {isContextMenuOpen && (
        <div
          className={classes(
            'check-outer-click-layer',
            style({
              position: 'fixed',
              width: '100vw',
              top: 0,
              left: 0,
              height: '100vh',
              zIndex: 5
            })
          )}
        />
      )}
    </div>
  )
}

export default ContextMenuButton

const contextMenuButtonStyle = (isActive: boolean) =>
  style(
    {
      background: isActive ? Blue80 : BlackOpacity60,
      width: '36px !important',
      height: '36px !important',
      position: 'absolute',
      borderRadius: '12px !important',
      top: 16,
      right: 16,
      display: isActive ? 'flex !important' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      zIndex: 1,
      backdropFilter: 'blur(12.5px)'
    },
    media(
      { maxWidth: Breakpoint.TABLET },
      {
        display: 'flex'
      }
    )
  )
