import axios, { AxiosResponse } from 'axios'
import { sessionIdInterceptor } from './SessionIDInterceptor'
import { ScheduleMilestone } from '../components/sites_grid/site_card/schedule/types'
import { tokenInterceptor } from './TokenInterceptor'

const OfficeAPIService = axios.create({
  baseURL: OfficeAPIBaseUrl
})

OfficeAPIService.interceptors.request.use(config => sessionIdInterceptor(config))
OfficeAPIService.interceptors.request.use(config => tokenInterceptor(config))

export async function updateMilestoneIsKey(
  customerId: string,
  siteId: string,
  scheduleId: string,
  milestoneId: string,
  isKey: boolean
): Promise<ScheduleMilestone> {
  return OfficeAPIService.put(
    `/api/customers/${customerId}/sites/${siteId}/schedules/${scheduleId}/milestones/${milestoneId}`,
    {
      isKey
    }
  ).then((response: AxiosResponse<{ scheduleMilestone: ScheduleMilestone }>) => {
    return response.data.scheduleMilestone
  })
}
