import useAppStore from './AppStore'
import { CustomerData, ShortSiteData } from './types'
import { shallow } from 'zustand/shallow'
import { loadSitesData, loadStatistic } from './services/CustomerDashboardApiService'
import { CARD_CHUNK_SIZE } from './layout-constants'
import { debounce } from './libs/debounce'
import axios, { AxiosError } from 'axios'

class SitesDataManager {
  private abortController: AbortController | undefined
  private unsubscribeDataFiltersChangedHandler: (() => void) | undefined
  private unsubscribeFilterChangedHandler: (() => void) | undefined
  private unsubscribeLoadedSitesSkipCountChangedHandler: (() => void) | undefined
  private unsubscribeCustomersChangedHandler: (() => void) | undefined

  subscribeToStore() {
    this.unsubscribeDataFiltersChangedHandler = useAppStore.subscribe(
      ({ filters, sorting, customerIdFilter, siteNameFilterString }) => ({
        filters,
        sorting,
        customerIdFilter,
        siteNameFilterString
      }),
      this.handleSitesDataFiltersChanged,
      { equalityFn: shallow }
    )

    this.unsubscribeFilterChangedHandler = useAppStore.subscribe(state => state.filters, this.handleFiltersChanged)

    this.unsubscribeLoadedSitesSkipCountChangedHandler = useAppStore.subscribe(
      state => state.loadedSitesSkipCount,
      this.handleLoadedSitesSkipCountChanged
    )

    this.unsubscribeCustomersChangedHandler = useAppStore.subscribe(
      state => state.customers,
      this.handleCustomersChanged
    )
  }

  unsubscribeFromStore() {
    if (this.unsubscribeDataFiltersChangedHandler) {
      this.unsubscribeDataFiltersChangedHandler()
    }

    if (this.unsubscribeLoadedSitesSkipCountChangedHandler) {
      this.unsubscribeLoadedSitesSkipCountChangedHandler()
    }

    if (this.unsubscribeFilterChangedHandler) {
      this.unsubscribeFilterChangedHandler()
    }

    if (this.unsubscribeCustomersChangedHandler) {
      this.unsubscribeCustomersChangedHandler()
    }
  }

  handleLoadedSitesSkipCountChanged = () => {
    this.debouncedLoadSitesChunk()
  }

  private handleSitesDataFiltersChanged = () => {
    useAppStore.getState().actions.clearSitesInfo()
    this.debouncedLoadSitesChunk()
  }

  private handleFiltersChanged = () => {
    this.debouncedLoadStatistic()
  }

  private handleCustomersChanged = () => {
    this.loadStatisticForAllSites()
  }

  // debounced version to prevent multiple requests on several consequent store updates
  private debouncedLoadSitesChunk = debounce(() => {
    this.loadSitesChunk()
  }, 10)

  private debouncedLoadStatistic = debounce(() => {
    this.loadStatistic()
  }, 10)

  async loadSitesChunk() {
    const { actions, customers, customerIdFilter, filters, sorting, siteNameFilterString, loadedSitesSkipCount } =
      useAppStore.getState()

    actions.setSitesDataLoading()
    const selectedCustomer = customers.find((c: CustomerData) => c.id === customerIdFilter)
    if (this.abortController) {
      this.abortController.abort()
    }
    this.abortController = new AbortController()
    try {
      if (!filters) {
        return
      }
      const dashboardData = await loadSitesData(
        selectedCustomer ? [selectedCustomer] : customers,
        { count: CARD_CHUNK_SIZE, skip: loadedSitesSkipCount },
        filters,
        sorting,
        siteNameFilterString,
        this.abortController.signal
      )
      actions.setTotalSites(dashboardData.totalSites)
      const mergedSitesData = dashboardData.sites.map(site => {
        const siteCustomer = customers.find((customer: CustomerData) => customer.id === site.customerId)
        const foundSite = siteCustomer?.sites.find((customerSite: ShortSiteData) => customerSite.id === site.siteId)
        if (!foundSite) {
          throw new Error(`Site with ${site.siteId} is not found among sites available for user`)
        }
        return site
      })
      const filteredCustomersData = mergedSitesData.filter(
        siteData => !blacklistCustomerIds.includes(siteData.customerId)
      )
      actions.setSitesDataLoaded(filteredCustomersData)
    } catch (err) {
      if ((err as AxiosError).code !== axios.AxiosError.ERR_CANCELED) {
        throw err
      }
    }
  }

  prepareStatisticRequest() {
    const { customers } = useAppStore.getState()
    return customers.flatMap((customer: CustomerData) =>
      customer.sites.map(site => ({
        siteId: site.id,
        customerId: customer.id,
        projectActivity: site.projectActivity
      }))
    )
  }

  async loadStatistic() {
    const { actions, filters } = useAppStore.getState()
    if (!filters) {
      return
    }
    actions.setCustomersDataLoading()
    const selectedSites = this.prepareStatisticRequest()
    const statisticData = await loadStatistic(selectedSites, filters)
    actions.setCustomerStatistic(statisticData)
  }

  async loadStatisticForAllSites() {
    const clearedFilters = {
      active: false,
      archived: false,
      pastDueMilestones: false,
      withLotViewer: false,
      withSchedule: false
    }
    const { actions } = useAppStore.getState()
    const selectedSites = this.prepareStatisticRequest()
    const statisticData = await loadStatistic(selectedSites, clearedFilters)
    const withLv = statisticData.some(c => !!c.withLotViewer)
    const withSchedules = statisticData.some(c => !!c.withSchedules)
    actions.setHasSitesWithLotViewer(withLv)
    actions.setHasSitesWithSchedules(withSchedules)
  }
}

const sitesDataManager = new SitesDataManager()
export default sitesDataManager
