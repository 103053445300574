import * as React from 'react'
import { Color } from 'csstype'
import { Gray20 } from '@traceair/tds'

type PlayIconProps = {
  color?: Color
}

const PlayIcon: React.FunctionComponent<PlayIconProps> = ({ color = Gray20 }: PlayIconProps = {}) => {
  return (
    <svg width='33' height='36' viewBox='0 0 33 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M30.6204 14.5515C33.3617 16.0333 33.3617 19.9667 30.6204 21.4484L5.78403 34.8735C3.17223 36.2853 -1.59083e-06 34.394 -1.46106e-06 31.425L-2.874e-07 4.57495C-1.57623e-07 1.606 3.17223 -0.285286 5.78403 1.1265L30.6204 14.5515Z'
        fill={color}
      />
    </svg>
  )
}

export default PlayIcon
