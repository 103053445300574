// Performance Timeline API: https://developer.mozilla.org/en-US/docs/Web/API/Performance

type AppPerformanceTimings = PerformanceNavigationTiming | PerformanceEntry

export default class PerformanceUtils {
  static accessible(): boolean {
    return !!performance && !!window.performance.getEntriesByType('navigation').length
  }

  static timeFromStart(): number {
    return window.performance.now()
  }

  static getApiTiming(parameter: string): number {
    const navigationTimings = (window.performance.getEntriesByType('navigation')[0] as AppPerformanceTimings).toJSON()
    return navigationTimings[parameter]
  }
}
