import * as React from 'react'
import { classes, media, style } from 'typestyle'
import { PlusIcon } from '@traceair/webapp-icons'
import { Button } from '@traceair/tds'
import track from '../../Tracking'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'
import UpsellRequestMenu from './UpsellRequestMenu'
import { Breakpoint } from '../../responsive-breakpoints'

const UpsellRequestControl = () => {
  const [menuOpened, setMenuOpened] = React.useState(false)
  const handleMenuClose = React.useCallback(() => {
    setMenuOpened(false)
  }, [setMenuOpened])

  const handleNewButtonClick = () => {
    track('Add new button clicked')
    setMenuOpened(!menuOpened)
  }

  return (
    <div className={classes('create-request-container', containerStyle)}>
      <Button
        className='create-request-menu-open-button'
        icon={PlusIcon}
        text={t7e(i18nKeys.New)}
        onClick={handleNewButtonClick}
      />
      {menuOpened && <UpsellRequestMenu onClose={handleMenuClose} />}
    </div>
  )
}

export default UpsellRequestControl

const containerStyle = style(
  {
    position: 'relative',
    zIndex: 2
  },
  media(
    { maxWidth: Breakpoint.SMALL_TABLET },
    {
      position: 'absolute',
      right: 0,
      top: 0
    }
  )
)
