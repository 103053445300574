import { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'
import useAppStore from '../AppStore'

export function tokenInterceptor(config: AxiosRequestConfig): InternalAxiosRequestConfig {
  const token = useAppStore.getState().token

  if (!config.headers) {
    config.headers = {}
  }
  config.headers['Authorization'] = `Bearer ${token}`
  return config as InternalAxiosRequestConfig
}
