/* eslint-disable no-constant-condition */
import jwtDecode from 'jwt-decode'
import { getErrorMessage } from './helpers'
import { getLoginService } from './services/LoginService'
import { wait } from './utils'

const RENEWAL_RESERVE_MS = 5 * 60 * 1000
const ERROR_RETRY_DELAY_MS = 1000
const ERROR_COUNT_LIMIT = 10

const fetchAndSetToken = async (permissions: string[], setter: (token: string) => void): Promise<string> => {
  const token = await getLoginService().me('', permissions)
  setter(token)
  return token
}

const calculateNextRequestDelay = (token: string): number => {
  const { exp } = jwtDecode<{ exp: number }>(token)
  const expirationTime = exp * 1000
  return expirationTime - Date.now() - RENEWAL_RESERVE_MS
}

const reloadIfErrorCountExceedsLimit = (errorCount: number): void => {
  if (errorCount > ERROR_COUNT_LIMIT) {
    window.location.reload()
  }
}

const runJwtTokenUpdateLoop = async (permissions: string[], setter: (token: string) => void): Promise<never> => {
  let errorCount = 0

  while (true) {
    try {
      const token = await fetchAndSetToken(permissions, setter)
      const nextRequestDelay = calculateNextRequestDelay(token)

      await wait(nextRequestDelay)
    } catch (e) {
      console.error('Failed to renew the token', getErrorMessage(e))

      errorCount++
      reloadIfErrorCountExceedsLimit(errorCount)

      await wait(ERROR_RETRY_DELAY_MS)
    }
  }
}

export const setInitialJwtTokenAndStartUpdateLoop = async (
  permissions: string[],
  setter: (token: string) => void
): Promise<void> => {
  const token = await fetchAndSetToken(permissions, setter)

  const nextRequestDelay = calculateNextRequestDelay(token)
  setTimeout(runJwtTokenUpdateLoop, nextRequestDelay, permissions, setter)
}
