import { CustomerData, ProjectActivity, ShortSiteData } from '../../types'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'

export const getActiveSites = (sites: ShortSiteData[]): ShortSiteData[] => {
  return sites.filter(site => site.projectActivity === ProjectActivity.Active)
}

export const getSitesCountText = (count: number, showSitesFound: boolean): string => {
  return `${count} ${showSitesFound ? t7e(i18nKeys.SitesFound) : t7e(i18nKeys.Sites)}`
}

export const getSortedCustomers = <T extends CustomerData>(customers: T[]): T[] =>
  [...customers].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
