import * as React from 'react'
import { Black, Select, ISelectOptionProps, White } from '@traceair/tds'
import { ExpandLessIcon, ExpandMoreIcon } from '@traceair/webapp-icons'
import track from '../../Tracking'
import { classes, media, style } from 'typestyle'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'
import { SortingDirection, SortingOrders } from '../../types'
import useAppStore, { AppStoreType, defaultDirections } from '../../AppStore'
import { saveUserFilters } from '../../helpers'
import { Breakpoint } from '../../responsive-breakpoints'

const SortingSelector = () => {
  const sorting = useAppStore((state: AppStoreType) => state.sorting)
  const actions = useAppStore((state: AppStoreType) => state.actions)

  const handleSortingOrderChange = React.useCallback(
    (value: string) => {
      track('Sorting order changed')
      if (value === sorting.key) {
        const oppositeDirection =
          sorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc
        actions.setSorting({ key: value, direction: oppositeDirection })
      } else {
        actions.setSorting({ key: value as SortingOrders, direction: defaultDirections[value as SortingOrders] })
      }
      saveUserFilters()
    },
    [sorting]
  )

  const createIcon = React.useCallback(
    (key: SortingOrders) => {
      return sorting.key === key && sorting.direction !== defaultDirections[sorting.key as SortingOrders] ? (
        <ExpandLessIcon color={sorting.key === key ? White : Black} />
      ) : (
        <ExpandMoreIcon color={sorting.key === key ? White : Black} />
      )
    },
    [sorting]
  )

  const options: ISelectOptionProps[] = React.useMemo(
    () => [
      {
        value: SortingOrders.SiteName,
        title: t7e(i18nKeys.SiteName),
        description:
          sorting.direction === defaultDirections[sorting.key as SortingOrders]
            ? t7e(i18nKeys.SortByNameDirect)
            : t7e(i18nKeys.SortByNameReverse),
        icon: createIcon(SortingOrders.SiteName)
      },
      {
        value: SortingOrders.ScanDate,
        title: t7e(i18nKeys.ScanDate),
        description:
          sorting.direction === defaultDirections[sorting.key as SortingOrders]
            ? t7e(i18nKeys.SortByTimeDirect)
            : t7e(i18nKeys.SortByTimeReverse),
        icon: createIcon(SortingOrders.ScanDate)
      },
      {
        value: SortingOrders.ScheduleUpdated,
        title: t7e(i18nKeys.ScheduleUpdated),
        description:
          sorting.direction === defaultDirections[sorting.key as SortingOrders]
            ? t7e(i18nKeys.SortByTimeDirect)
            : t7e(i18nKeys.SortByTimeReverse),
        icon: createIcon(SortingOrders.ScheduleUpdated)
      },
      {
        value: SortingOrders.ScheduleProgress,
        title: t7e(i18nKeys.ScheduleProgress),
        description:
          sorting.direction === defaultDirections[sorting.key as SortingOrders]
            ? t7e(i18nKeys.SortByProgressDirect)
            : t7e(i18nKeys.SortByProgressReverse),
        icon: createIcon(SortingOrders.ScheduleProgress)
      }
    ],
    [sorting, createIcon]
  )

  return (
    <Select
      label={t7e(i18nKeys.SortBy)}
      value={sorting.key}
      onChange={handleSortingOrderChange}
      className={classes('sort-select', sortSelectStyle)}
      options={options}
    />
  )
}

export default SortingSelector

const sortSelectStyle = style(
  {
    marginRight: 16,
    width: 224
  },
  media(
    { maxWidth: Breakpoint.SMALL_TABLET },
    {
      marginRight: 0,
      width: '100%'
    }
  )
)
