import React from 'react'
import * as Sentry from '@sentry/react'
import { cssRaw } from 'typestyle'
import { createRoot } from 'react-dom/client'
import { Black } from '@traceair/tds'
import pkg from '../../package.json'
import App from '../App'
import { handleImageLoadErrors } from '../libs/sentryUtils'

const appVersion = pkg.version

if (SENTRY.enabled) {
  Sentry.init({
    dsn: SENTRY.dsn,

    release: appVersion,
    environment: process.env.NODE_ENV
  })

  handleImageLoadErrors()
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<App />)

cssRaw(`
  html, body, #root {
    width: 100%;
    height: 100%;
  }
  
  body {
    background: ${Black};
    margin: 0;
  }


  button {
    background: none;
    border: none;
    padding: 0;
    outline: none;
    cursor: pointer;
  }
  
  // Prevent overscrolling behaviors on the select list: disabling the page scrolling and pull-to-refresh on mobile
  // while the select list is scrolled.
  .ta-select-list {
    overscroll-behavior: none;
  }
`)
