import React, { useState } from 'react'
import { White, Window, WindowBody, WindowHeader } from '@traceair/tds'
import { classes, style } from 'typestyle'
import UserProfileForm from './UserProfileForm'
import useAppStore from '../../AppStore'
import { IconSize, LockIcon, UserIcon } from '@traceair/webapp-icons'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'
import WindowCloseButton from './WindowCloseButton'
import ChangePassForm from './ChangePassForm'
import track from '../../Tracking'

enum ProfileWindows {
  UserData,
  ChangePassword
}

const UserProfileWindowContainer: React.FunctionComponent = () => {
  const [currentWindow, setCurrentWindow] = useState<ProfileWindows>(ProfileWindows.UserData)
  const isUserDataWindow = currentWindow === ProfileWindows.UserData
  return (
    <Window
      className={classes('user-profile-window', style({ width: 244 }))}
      header={
        <WindowHeader
          title={isUserDataWindow ? t7e(i18nKeys.ProfileForm) : t7e(i18nKeys.ProfileFormChangePassword)}
          leftCell={isUserDataWindow ? <UserIcon color={White} /> : <LockIcon size={IconSize.S} color={White} />}
          rightCell={
            <WindowCloseButton
              onClick={() => {
                track('Close user profile window Click')
                isUserDataWindow
                  ? useAppStore.getState().actions.hideWindow()
                  : setCurrentWindow(ProfileWindows.UserData)
              }}
            />
          }
        />
      }
    >
      <WindowBody>
        {isUserDataWindow ? (
          <UserProfileForm onChangePassFormActivate={() => setCurrentWindow(ProfileWindows.ChangePassword)} />
        ) : (
          <ChangePassForm onChangePassFormDeactivate={() => setCurrentWindow(ProfileWindows.UserData)} />
        )}
      </WindowBody>
    </Window>
  )
}

export default UserProfileWindowContainer
