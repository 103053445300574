/* eslint-disable */
let initialized = false;
class InspectletUtils {
  static init(inspectletId, user) {
    if (initialized) {
      return;
    }
    initialized = true;
    window.__insp = window.__insp || [];
    __insp.push(['wid', inspectletId]);
    const { email, name, lastname } = user;
    __insp.push(['tagSession', { email, name: `${name} ${lastname}` }]);
    __insp.push(['identify', email]);
    (function () {
      function ldinsp() {
        if (typeof window.__inspld != 'undefined') return;
        window.__inspld = 1;
        var insp = document.createElement('script');
        insp.type = 'text/javascript';
        insp.async = true;
        insp.id = 'inspsync';
        insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js';
        var x = document.getElementsByTagName('script')[0];

        x.parentNode.insertBefore(insp, x);
      }
      setTimeout(ldinsp, 500);
      document.readyState != 'complete'
        ? window.attachEvent
          ? window.attachEvent('onload', ldinsp)
          : window.addEventListener('load', ldinsp, false)
        : ldinsp();
    })();
  }
}

export default InspectletUtils;
