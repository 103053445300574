import { checkPermissions, IAcl, ICurrentUser, PermissionLevel } from '../../../../jwt-utils'
import { CustomerId, SiteData, SiteId } from '../../../../types'

export function allowToPickMilestones(ACL: IAcl, user: ICurrentUser, site: SiteData) {
  const permissions = [
    {
      name: 'FRONTOFFICE_API_SCHEDULE_EDIT',
      level: PermissionLevel.Site
    }
  ]

  return checkPermissions(permissions, ACL, site.customerId, site.siteId)
}

export function allowToManageUsersOnSite(ACL: IAcl, site: SiteData) {
  const permissions = [
    {
      name: 'CSU_API_USER_LIST_SITE',
      level: PermissionLevel.Site
    },
    {
      name: 'CSU_API_USER_ROLE_ASSIGN_SITE',
      level: PermissionLevel.Site
    },
    {
      name: 'CSU_API_USER_ROLE_REVOKE_SITE',
      level: PermissionLevel.Site
    }
  ]

  return checkPermissions(permissions, ACL, site.customerId, site.siteId)
}

export function allowToChangeProjectStatus(user: ICurrentUser, ACL: IAcl, customerId: CustomerId, siteId: SiteId) {
  return (
    user.company?.id === customerId ||
    checkPermissions(
      [
        {
          name: 'CSU_SITE_ACTIVITY_STATUS_EDIT',
          level: PermissionLevel.Site
        }
      ],
      ACL,
      customerId,
      siteId
    )
  )
}
