// forked from https://github.com/boynet/Influxdb-js-client
// don't want another dependency + need jwt authorization

export class InfluxDB {
  sendPointsOnClose: boolean
  host: string
  points: InfluxDBPoint[]
  beaconSent: boolean

  constructor(host: string, sendPointsOnClose = false) {
    this.sendPointsOnClose = sendPointsOnClose
    this.host = host
    this.points = []
    this.beaconSent = false
    if (this.sendPointsOnClose) this.registerUnloadEvent()
    return this
  }

  registerUnloadEvent() {
    // need both events to work in chrome http://stackoverflow.com/a/20322988/1368683
    window.addEventListener('unload', this.sendBeacon, false)
    window.onbeforeunload = this.sendBeacon
  }

  private sendBeacon = () => {
    // need this polyfill https://github.com/miguelmota/Navigator.sendBeacon
    if (this.beaconSent) return
    if (this.points && this.points.length === 0) return
    this.beaconSent = true
    if (!navigator || !navigator.sendBeacon) return
    const data = this.implodePoints()
    navigator.sendBeacon(this.host, data)
  }

  point(point: InfluxDBPoint) {
    return this.addPoint(point)
  }

  private addPoint(point: InfluxDBPoint): InfluxDB {
    if (point.isValid()) {
      this.points.push(point)
    }
    return this
  }

  private implodePoints() {
    if (this.points.length === 0) return ''
    let data = ''
    for (let index = 0; index < this.points.length; ++index) {
      if (!this.points[index].isValid()) {
        this.points.slice(index, 1)
        continue
      }
      data = data + this.points[index].getLine()
    }
    return data
  }

  send(accessToken: string) {
    if (this.points.length === 0) return false
    const data = this.implodePoints()
    if (data) {
      const request = new XMLHttpRequest()
      request.open('POST', this.host, true)
      request.setRequestHeader('Content-Type', 'text/plain; charset=UTF-8')
      request.setRequestHeader('Authorization', `Bearer ${accessToken}`)
      request.send(data)
    }

    this.points = []
  }
}

export type InfluxField = {
  [key: string]: string | number
}

export type InfluxTags = {
  [key: string]: string | number | boolean
}

export class InfluxDBPoint {
  private key?: string

  constructor(
    private fields: InfluxField,
    private tags: InfluxTags = {},
    private readonly time: number = Date.now() * 1e6 /* convert to nanoseconds */
  ) {}

  isValid() {
    return this.key && this.fields
  }

  getTags() {
    return this.tags
  }

  setTags(tags: InfluxTags) {
    this.tags = tags
  }

  getFields() {
    return this.fields
  }

  setFields(fields: InfluxField) {
    this.fields = fields
  }

  getKey() {
    return this.key
  }

  setKey(key: string) {
    this.key = key
  }

  getLine() {
    if (!this.key && !this.fields) {
      return
    }

    let line = this.key
    if (this.tags) {
      line = line + ',' + objToString(this.tags)
    }
    if (this.fields) {
      // fields requires quoted string values: https://docs.influxdata.com/influxdb/v1.7/write_protocols/line_protocol_tutorial/
      // Do double quote field values that are strings. (c)
      const makeValuesQuoted = true
      line = line + ' ' + objToString(this.fields, makeValuesQuoted)
    }
    if (this.time) {
      line = line + ' ' + this.time
    }
    line = line + '\n'
    return line
  }
}

function sortObjectByKey(obj: InfluxField | InfluxTags) {
  if (!Array.prototype.forEach) return obj
  if (!Array.prototype.sort) return obj
  if (!Object.keys) return obj
  const ordered: InfluxField | InfluxTags = {}
  Object.keys(obj)
    .sort()
    .forEach(function (key: string) {
      ordered[key] = obj[key]
    })
  return ordered
}

function objToString(obj: InfluxField | InfluxTags, makeValuesQuoted = false) {
  let i = 0
  let str = ''
  let value
  // Tags should be sorted by key before being sent for best performance https://docs.influxdata.com/influxdb/v0.13/write_protocols/line/#key
  obj = sortObjectByKey(obj)
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // todo: right now not supporting space in tags where it should be support with back slash
      key = key.replace(/\s+/g, '')
      value = obj[key] !== undefined ? obj[key].toString() : ''
      value = value.replace(/\s+/g, '')
      i++
      if (i > 1) str = str + ','

      // Make values quoted when we have a special flag and the value is not a number
      str += key + '=' + (makeValuesQuoted && typeof obj[key] !== 'number' ? `"${value}"` : value)
    }
  }
  return str
}
