import { TAMetricsWriter } from '../TAMetricsWriter'
import PerformanceUtils from './PerformanceUtils'
import { InfluxDBPoint } from '../InfluxDBWriter'
import { CustomerData } from '../../types'

const appLoadMetricWriter = new TAMetricsWriter('AppLoadMetrics')

const timingToShip = [
  'domainLookupStart',
  'domainLookupEnd',
  'connectEnd',
  'requestStart',
  'responseStart',
  'responseEnd',
  'domLoading',
  'domInteractive',
  'domComplete',
  'loadEventEnd'
]

type EventMetric = {
  [name: string]: number | string
}

const metricsCollected: EventMetric = {
  userAgent: navigator.userAgent
}

function addMetric(name: string) {
  metricsCollected[name] = PerformanceUtils.timeFromStart()
}

export function startAppLoadMetrics() {
  addMetric('appLoadingStart')
}

export function numericMetrics(customers: CustomerData[]) {
  const sites = customers.map(c => c.sites)
  const initialNumbersToTrack = {
    customersNum: customers.length,
    sitesNum: sites.length
  }
  for (const [key, value] of Object.entries(initialNumbersToTrack)) {
    if (value) {
      metricsCollected[key] = value
    }
  }
}

export function endAppLoadMetrics() {
  function addTimings() {
    for (const prop of timingToShip) {
      const value = PerformanceUtils.getApiTiming(prop)
      if (value) {
        metricsCollected[prop] = value
      }
    }
  }

  function shipMetrics() {
    const point = new InfluxDBPoint(metricsCollected)
    appLoadMetricWriter.addPoint(point)
    appLoadMetricWriter.send()
  }

  if (PerformanceUtils.accessible()) {
    addMetric('appLoadingEnd')
    addTimings()
    shipMetrics()
  }
}
