import { create } from 'zustand'
import { ShortSiteData } from '../../types'

export enum UpsellPopupType {
  AddLotViewer = 'add-lot-viewer',
  AddSchedules = 'add-schedules',
  AddPanoramas = 'add-panoramas',
  RequestFlights = 'request-flights'
}

export type ActiveSite = Omit<ShortSiteData, 'projectActivity'> & { customerId: string }

type State = {
  activeSite?: ActiveSite
  activePopup?: UpsellPopupType
}

type Actions = {
  openPopup: (activePopup: UpsellPopupType, activeSite?: ActiveSite) => void

  closePopup: () => void
}

const initialState: State = {
  activeSite: undefined,
  activePopup: undefined
}

export const PopupStore = create<State & Actions>()(set => ({
  ...initialState,

  openPopup: (activePopup, activeSite) => set({ activePopup, activeSite }),
  closePopup: () => set({ activePopup: undefined, activeSite: undefined })
}))

export default PopupStore

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
window.__popupStore = PopupStore
