import React from 'react'
import { INavigationBarListItemProps } from '@traceair/tds'
import { UserIcon } from '@traceair/webapp-icons'
import track from '../../../../Tracking'
import useAppStore from '../../../../AppStore'
import UserProfileWindow from '../../../user_profile_window/UserProfileWindow'
import { t7e } from '../../../../i18n/i18n'
import { i18nKeys } from '../../../../i18n/i18nkeys'

export function createUserProfileListItem(): INavigationBarListItemProps {
  return {
    icon: UserIcon,
    title: t7e(i18nKeys.Profile),
    className: 'user-profile-list-item',
    onClick: () => {
      track('User profile Button Click')
      useAppStore.getState().actions.showWindow(<UserProfileWindow />)
    }
  }
}
