import * as React from 'react'
import { FunctionComponent } from 'react'
import { classes, style } from 'typestyle'
import { IconSize, SearchIcon } from '@traceair/webapp-icons'
import { Gray100, type14Medium, type12Medium, TextItem } from '@traceair/tds'
import { t7e } from '../i18n/i18n'
import { i18nKeys } from '../i18n/i18nkeys'

interface INoSitesFoundProps {
  showHint: boolean
}

const NoSitesFound: FunctionComponent<INoSitesFoundProps> = ({ showHint }) => {
  return (
    <div className={containerStyle}>
      <SearchIcon size={IconSize.L} />
      <TextItem text={t7e(i18nKeys.NoSitesFound)} style={classes(textStyle, type14Medium)} />
      {showHint && <TextItem text={t7e(i18nKeys.AddNewProject)} style={classes(descriptionStyle, type12Medium)} />}
    </div>
  )
}

export default NoSitesFound

const containerStyle = style({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '44px 0',
  textAlign: 'center',
  width: '100%'
})

const textStyle = style({
  margin: '8px 0'
})

const descriptionStyle = style({
  color: Gray100,
  maxWidth: 180
})
