import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { ScheduleMilestone } from '../schedule/types'
import { Checkbox, type14Medium, Menu, MenuItem, type12Medium, TextItem } from '@traceair/tds'
import { updateMilestoneVisibility } from '../../../../helpers'
import { classes, style } from 'typestyle'
import { t7e } from '../../../../i18n/i18n'
import { i18nKeys } from '../../../../i18n/i18nkeys'
import { CustomerData, SiteData } from '../../../../types'
import useAppStore from '../../../../AppStore'
import track from '../../../../Tracking'
import { format } from 'date-fns'

type MilestonesMenuProps = {
  customer: CustomerData
  site: SiteData
}

const MilestonesMenu: React.FunctionComponent<MilestonesMenuProps> = ({ customer, site }) => {
  const schedule = site.schedule!
  const countActiveMilestones = schedule.milestones.filter(milestone => milestone.isKey || milestone.pending)

  const [sortedMilestoneIds, setSortedMilestoneIds] = useState<ScheduleMilestone['id'][]>([])
  useEffect(() => {
    setSortedMilestoneIds(
      mergedSnapshotMilestones
        .slice()
        .sort((m1, m2) => {
          if (m1.isKey && !m2.isKey) return -1
          if (!m1.isKey && m2.isKey) return 1

          const date1 = new Date(m1.plannedDoneDate.value)
          const date2 = new Date(m2.plannedDoneDate.value)
          return date1.getTime() - date2.getTime()
        })
        .map(m => m.id)
    )
    // NOTE: sort milestones only once on open and then preserve ordering after "isKey" update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mergedSnapshotMilestones = schedule.latestProgressSnapshot.milestones.map(snapshotMilestone => {
    const milestone = schedule.milestones.find(milestone => milestone.id === snapshotMilestone.milestone)

    return {
      ...snapshotMilestone,
      id: snapshotMilestone.milestone,
      name: milestone?.name ?? '',
      plannedDoneDate: snapshotMilestone.plannedDoneDate,
      isKey: milestone?.isKey ?? false,
      pending: milestone?.pending ?? false
    }
  })

  const handleKeyMilestoneChange = useCallback(
    async (milestone: ScheduleMilestone) => {
      if (milestone.pending) {
        return
      }
      useAppStore.getState().actions.setMilestoneUpdatePending(customer.id, site.siteId, milestone.id, true)
      try {
        const updatedMilestone: ScheduleMilestone | undefined = await updateMilestoneVisibility(
          customer.id,
          site.siteId,
          schedule.id,
          milestone.id,
          !milestone.isKey
        )
        if (!updatedMilestone) {
          return
        }
        useAppStore
          .getState()
          .actions.setMilestoneVisibility(updatedMilestone.id, updatedMilestone.isKey, site.siteId, customer.id)
      } catch (e) {
        console.error(e, 'Failed to update milestone visibility')
      }
      useAppStore.getState().actions.setMilestoneUpdatePending(customer.id, site.siteId, milestone.id, false)
    },
    [customer.id, site.siteId, schedule.id]
  )

  return (
    <Menu className={classes(keyWindowStyle, 'key-milestones-menu')}>
      <MenuItem
        icon={null}
        title={<TextItem style={type12Medium} text={t7e(i18nKeys.PickKeyMilestones)} />}
        disabled
        className={menuHeaderStyle}
      />
      {sortedMilestoneIds.map(milestoneId => {
        const milestone = mergedSnapshotMilestones.find(m => m.id === milestoneId)
        if (!milestone) {
          return
        }
        return (
          <MenuItem
            key={milestone.id}
            icon={<Checkbox onChange={() => ({})} checked={milestone.isKey} loading={milestone.pending} />}
            onClick={e => {
              e && e.stopPropagation()
              track('Check Milestone clicked')
              handleKeyMilestoneChange(milestone)
            }}
            title={<TextItem style={classes(type14Medium, milestoneNameStyle)} text={milestone.name} />}
            description={`${format(new Date(milestone.plannedDoneDate.value), 'MM/dd/yy')}`}
            disabled={!milestone.isKey && countActiveMilestones.length >= 3 && !milestone.pending}
          />
        )
      })}
    </Menu>
  )
}

const keyWindowStyle = style({
  maxHeight: 280,
  width: 'calc(100% - 8px)',
  scrollbarWidth: 'none',
  overflow: 'auto',
  zIndex: 10
})

const menuHeaderStyle = style({
  paddingLeft: 8,
  $nest: {
    '& .ta-left-cell': { display: 'none' }
  }
})

const milestoneNameStyle = style({
  flex: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 240
})
export default MilestonesMenu
