import { INavigationBarListItemProps } from '@traceair/tds'
import { HelpIcon } from '@traceair/webapp-icons'
import track from '../../../../Tracking'

export default function createUserGuideListItem(): INavigationBarListItemProps {
  return {
    icon: HelpIcon,
    title: 'User Guide',
    onClick: () => {
      track('User Guide link click')
      window.open(UserGuideUrl, '_blank')
    }
  }
}
