import * as React from 'react'
import { FunctionComponent, useEffect } from 'react'
import { classes, media, style } from 'typestyle'
import { BlackOpacity60, ButtonIcon, Gray10, Gray100, Gray20, White } from '@traceair/tds'
import { SIDEBAR_WIDTH } from '../../layout-constants'
import CustomersList from './CustomersList'
import SearchProjectsInput from './SearchProjectsInput'
import useAppStore, { AppStoreType } from '../../AppStore'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'
import { DoubleArrowLeftIcon, DoubleArrowRightIcon } from '@traceair/webapp-icons'
import track from '../../Tracking'
import FiltersContainer from './FiltersContainer'
import { useShallow } from 'zustand/react/shallow'
import { Breakpoint, hideOnTabletsOrMobiles, showOnlyOnTabletsOrMobiles } from '../../responsive-breakpoints'
import { useSwipe, useWindowWidth } from '../../hooks'

interface ISidebarProps {
  onVisibilityChanged: (value: boolean) => void
  isCollapsed: boolean
}

const Sidebar: FunctionComponent<ISidebarProps> = ({ onVisibilityChanged, isCollapsed }) => {
  const { siteNameFilterString, initialLoading } = useAppStore(
    useShallow((state: AppStoreType) => ({
      siteNameFilterString: state.siteNameFilterString,
      initialLoading: state.customersDataLoading
    }))
  )

  const windowWidth = useWindowWidth()
  const isTabletOrMobile = windowWidth <= Breakpoint.TABLET

  useEffect(() => {
    if (!isCollapsed && isTabletOrMobile) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isCollapsed, isTabletOrMobile])

  const handleCollapseButtonClick = () => {
    track('Sidebar collapse button clicked')
    onVisibilityChanged(false)
  }

  const handleExpandButtonClick = () => {
    track('Sidebar expand button clicked')
    onVisibilityChanged(true)
  }

  const handleBackdropClick = () => {
    track('Sidebar backdrop clicked')
    onVisibilityChanged(false)
  }

  const onLeftSwipe = () => {
    track('Sidebar swiped to left')
    onVisibilityChanged(false)
  }

  const [handleTouchStart, handleTouchEnd] = useSwipe(onLeftSwipe, null)

  return (
    <div
      className={classes(
        'sidebar-wrapper',
        wrapperStyle,
        isCollapsed && wrapperCollapsedStyle,
        isCollapsed && hideOnTabletsOrMobiles,
        isCollapsed && 'collapsed',
        initialLoading && skeletonStyle
      )}
    >
      <div
        className={classes('sidebar-backdrop', showOnlyOnTabletsOrMobiles, sidebarBackdropStyle)}
        onClick={handleBackdropClick}
      />
      <div
        className={classes('sidebar', sidebarStyle, isCollapsed && sidebarCollapsedStyle)}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <div className={classes('dashboard-sidebar-header', headerStyle)}>
          {t7e(i18nKeys.Projects)}
          <ButtonIcon
            size='xs'
            className={'toggle-sidebar-collapse'}
            icon={<DoubleArrowLeftIcon color={Gray100} />}
            styleType='tertiary'
            onClick={handleCollapseButtonClick}
          />
        </div>
        <SearchProjectsInput siteNameFilterString={siteNameFilterString} />
        <div className={classes('customers-list-container', customerListStyle)}>
          <CustomersList onSidebarVisibilityChanged={onVisibilityChanged} />
        </div>
        <FiltersContainer />
      </div>
      {isCollapsed && (
        <ButtonIcon
          size='xs'
          icon={<DoubleArrowRightIcon color={Gray100} />}
          styleType='tertiary'
          onClick={handleExpandButtonClick}
          className={classes('sidebar-expand-button', sidebarExpandButton)}
        />
      )}
    </div>
  )
}

const wrapperStyle = style(
  {
    border: `1px solid ${Gray20}`,
    borderRadius: 24,
    boxShadow: '0px 9px 25px 0px rgba(0, 0, 0, 0.07), 0px 1.752px 3.13px 0px rgba(0, 0, 0, 0.04)',
    boxSizing: 'border-box',
    gridColumn: 2,
    maxHeight: 'calc(100vh - 48px * 2)',
    overflow: 'hidden',
    position: 'sticky',
    top: 48,
    width: SIDEBAR_WIDTH
  },
  media(
    { maxWidth: Breakpoint.TABLET },
    {
      background: White,
      borderRadius: 0,
      bottom: 0,
      left: 0,
      maxHeight: 'none',
      position: 'fixed',
      top: 0,
      zIndex: 3
    }
  )
)

const wrapperCollapsedStyle = style({
  borderRadius: '0 24px 24px 0',
  gridColumn: 1,
  marginLeft: -80,
  width: 48
})

const sidebarStyle = style(
  {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    fontFamily: 'Roboto',
    height: '100%',
    maxHeight: 'calc(100vh - 48px * 2)',
    overflow: 'hidden',
    background: White,
    width: SIDEBAR_WIDTH
  },
  media(
    { maxWidth: Breakpoint.TABLET },
    {
      maxHeight: 'none',
      position: 'relative'
    }
  )
)

const sidebarCollapsedStyle = style({
  visibility: 'hidden',
  pointerEvents: 'none'
})

const skeletonStyle = style({
  backgroundColor: Gray10,
  $nest: {
    '& > *': {
      opacity: 0
    }
  }
})

const sidebarBackdropStyle = style({
  background: BlackOpacity60,
  bottom: 0,
  content: '""',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0
})

const headerStyle = style({
  display: 'flex',
  fontSize: 20,
  fontWeight: 500,
  justifyContent: 'space-between',
  lineHeight: 1.2,
  padding: '24px 16px 8px'
})

const customerListStyle = style(
  {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '0 8px'
  },
  media(
    { minHeight: 1000 },
    {
      minHeight: 486
    }
  )
)

const sidebarExpandButton = style({
  marginTop: -12,
  position: 'absolute',
  right: 16,
  top: '50%'
})

export default Sidebar
