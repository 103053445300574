import { JWTToken } from './jwt-utils'
import useAppStore, { initialFilters } from './AppStore'
import { getLoginService } from './services/LoginService'
import { updateMilestoneIsKey } from './services/OfficeAPIService'
import { ScheduleMilestone } from './components/sites_grid/site_card/schedule/types'
import { getFilters, loadSitesList, saveFilters } from './services/CustomerDashboardApiService'
import urljoin from 'url-join'
import { CustomerData, SiteData } from './types'

export async function loadInitialInfo() {
  useAppStore.getState().actions.setCustomersDataLoading()
  const customersData = await loadSitesList()
  const filteredCustomersData = customersData.filter(customerData => !blacklistCustomerIds.includes(customerData.id))

  useAppStore.getState().actions.setCustomersDataLoaded(filteredCustomersData)

  return customersData
}

export async function getToken(permissions: string[]): Promise<JWTToken> {
  try {
    return getLoginService().me('', permissions)
  } catch (err) {
    console.error('Failed to get token', getErrorMessage(err))
    throw err
  }
}

export async function updateMilestoneVisibility(
  customerId: string,
  siteId: string,
  scheduleId: string,
  milestoneId: string,
  isKey: boolean
): Promise<ScheduleMilestone | undefined> {
  return await updateMilestoneIsKey(customerId, siteId, scheduleId, milestoneId, isKey)
}

export function saveUserFilters(): void {
  const { user, filters, sorting } = useAppStore.getState()
  if (!user || !filters) {
    return
  }
  saveFilters(user.email, filters, sorting)
}

export async function getUserFilters(): Promise<void> {
  const { user, actions } = useAppStore.getState()
  if (!user) {
    return
  }
  const filterSettings = await getFilters(user.email)
  if (filterSettings === null) {
    actions.setFilters(initialFilters)
  } else {
    const { filter, sort } = filterSettings
    actions.setFilters(filter)
    actions.setSorting(sort)
  }
}

export function getErrorMessage(err: Error | unknown): string {
  if (err instanceof Error) {
    return err.message
  } else {
    return <string>err
  }
}

export function buildOfficeLink(customer: CustomerData, site: SiteData) {
  return urljoin(OfficeUrl, `#?company=${customer.id}&site=${site.siteId}&date=${site.latestScan?.doneDate || ''}`)
}

export function addIntercom(token: string) {
  const w = window
  const ic = w.Intercom
  if (typeof ic === 'function') {
    ic('reattach_activator')
    ic('update', w.intercomSettings)
  } else {
    const d = document
    const i = function () {
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      i.c(arguments)
    }
    // @ts-ignore
    i.q = []
    // @ts-ignore
    i.c = function (args) {
      // @ts-ignore
      i.q.push(args)
    }
    // @ts-ignore
    w.Intercom = i
    const l = function () {
      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/' + token
      const x = d.getElementsByTagName('script')[0]
      // @ts-ignore
      x.parentNode.insertBefore(s, x)
    }
    l()
    // @ts-ignore
    if (w.attachEvent) {
      // @ts-ignore
      w.attachEvent('onload', l)
    } else {
      w.addEventListener('load', l, false)
    }
  }
}
