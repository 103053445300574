import { FC } from 'react'
import { stylesheet } from 'typestyle'
import { Blue80 } from '@traceair/tds'
import { OpenInNewIcon } from '@traceair/webapp-icons'
import { UpsellPopupType } from './popupStore'
import { i18nKeys } from '../../i18n/i18nkeys'
import { t7e } from '../../i18n/i18n'

export const GuideLink: FC<{ activePopup: UpsellPopupType }> = ({ activePopup }) => {
  return (
    <a className={css.root} href={getHref(activePopup)} target='_blank' rel='noreferrer'>
      <div>{t7e(i18nKeys.UpsellPopupGuideLink)}</div>
      <OpenInNewIcon color={Blue80} size={16} />
    </a>
  )
}

function getHref(activePopup: UpsellPopupType) {
  switch (activePopup) {
    case UpsellPopupType.AddLotViewer:
      return 'https://www.traceair.net/landing/lot-viewer?utm_source=dashboard_upsell'
    case UpsellPopupType.AddSchedules:
      return 'https://www.traceair.net/whats-new/introducing-schedule?utm_source=dashboard_upsell'

    default:
      return ''
  }
}

const css = stylesheet({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    flexShrink: 0,
    color: Blue80,
    gap: 2,
    cursor: 'pointer',
    textDecoration: 'none'
  }
})
