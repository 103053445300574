export const headerHeight = 68

export const APPLICATION_LAYOUT_MAX_WIDTH = 1920

export const MAIN_CONTAINER_MAX_WIDTH = 1280

export const SIDEBAR_WIDTH = 296

export const CARD_CHUNK_SIZE = 50
export const CARD_HEIGHT = 440
