import * as React from 'react'
import { White } from '@traceair/tds'
import { classes, media, style } from 'typestyle'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import useAppStore, { AppStoreType } from '../../AppStore'
import { CARD_CHUNK_SIZE } from '../../layout-constants'
import { SiteData } from '../../types'
import SiteCardSkeleton from './site_card/SiteCardSkeleton'
import SiteCard from './site_card/SiteCard'
import SiteListTopContainer from './SiteListTopContainer'
import NoSitesFound from '../NoSitesFound'
import StatisticBlock from './site_card/StatisticBlock'
import { Breakpoint } from '../../responsive-breakpoints'

interface ISitesContainerProps {
  isSidebarCollapsed: boolean
  onSidebarVisibilityChanged: (value: boolean) => void
}

const SitesContainer: FunctionComponent<ISitesContainerProps> = ({
  isSidebarCollapsed,
  onSidebarVisibilityChanged
}) => {
  const { dataLoading, sites } = useAppStore(
    useShallow((state: AppStoreType) => ({
      dataLoading: state.sitesDataLoading,
      sites: state.sites
    }))
  )

  const [skeletonCardsNumber, setSkeletonCardsNumber] = useState(CARD_CHUNK_SIZE)
  const sitesContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = sitesContainerRef.current
    if (container === null || sites.length === 0) {
      return
    }

    const siteCards = container.querySelector('.dashboard-sitecards-group-sites')
    if (!siteCards) return

    const items = Array.from(siteCards.getElementsByClassName('dashboard-site-card')) as HTMLDivElement[]
    if (items.length === 0) return

    let itemsPerRow = items.findIndex(item => item.offsetTop !== items[0].offsetTop)
    if (itemsPerRow === -1) {
      itemsPerRow = items.length
    }

    let itemsInLastRow =
      items.length - items.findLastIndex(item => item.offsetTop !== items[items.length - 1].offsetTop) - 1 ||
      items.length
    if (itemsInLastRow === -1) {
      itemsInLastRow = items.length
    }
    const itemsToAdd = 2 * itemsPerRow - itemsInLastRow
    setSkeletonCardsNumber(itemsToAdd)
  }, [dataLoading, sites])

  return (
    <div
      className={classes('dashboard-sitecards-group', groupStyle, isSidebarCollapsed && sidebarCollapsedStyle)}
      ref={sitesContainerRef}
    >
      <SiteListTopContainer onSidebarVisibilityChanged={onSidebarVisibilityChanged} />
      <StatisticBlock />
      {(dataLoading || sites.length > 0) && (
        <div className={classes('dashboard-sitecards-group-sites', sitesListStyle(isSidebarCollapsed))}>
          {sites.length > 0 &&
            sites.map((site: SiteData) => <SiteCard key={`${site.customerId}-${site.siteId}`} site={site} />)}
          {dataLoading &&
            Array.from(Array(sites.length === 0 ? CARD_CHUNK_SIZE : skeletonCardsNumber)).map((_, i) => (
              <SiteCardSkeleton key={i} />
            ))}
        </div>
      )}
      {!dataLoading && sites.length === 0 && <NoSitesFound showHint={true} />}
    </div>
  )
}

const groupStyle = style({
  alignSelf: 'start',
  background: White,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  marginTop: 2
})

const sidebarCollapsedStyle = style({
  gridColumn: 2
})

const sitesListStyle = (isSidebarCollapsed: boolean) =>
  style(
    {
      display: 'grid',
      gridColumn: 3,
      gridTemplateColumns: `repeat( ${isSidebarCollapsed ? 4 : 3}, 1fr )`,
      gap: 12
    },
    media(
      { maxWidth: Breakpoint.TABLET },
      {
        gridTemplateColumns: `repeat( 3, 1fr )`
      }
    ),
    media(
      { maxWidth: Breakpoint.SMALL_TABLET },
      {
        gridTemplateColumns: `repeat( 2, 1fr )`
      }
    ),
    media(
      { maxWidth: Breakpoint.SMALL_MOBILE },
      {
        gridTemplateColumns: `repeat( 1, 1fr )`
      }
    )
  )

export default SitesContainer
