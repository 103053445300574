import * as React from 'react'
import { classes, media, style } from 'typestyle'
import { Blue80, Button, Gray10, Gray100, Gray20, White } from '@traceair/tds'
import { PadIcon, PlusIcon, ScheduleIcon } from '@traceair/webapp-icons'
import { t7e } from '../../../i18n/i18n'
import { i18nKeys } from '../../../i18n/i18nkeys'
import track from '../../../Tracking'
import useAppStore from '../../../AppStore'
import { CustomerData } from '../../../types'
import { Breakpoint } from '../../../responsive-breakpoints'
import { UpsellPopupType, showUpsellPopup } from '../../upsell_popup'

const StatisticBlock = () => {
  const customerIdFilter = useAppStore(state => state.customerIdFilter)
  const user = useAppStore(state => state.user)
  const customersWithStatistic = useAppStore(state => state.customersWithStatistic)
  const isLoading = useAppStore(state => state.customersDataLoading)
  const hasSitesWithLotViewer = useAppStore(state => state.hasSitesWithLotViewer)
  const hasSitesWithSchedules = useAppStore(state => state.hasSitesWithSchedules)

  const selectedCustomer = customersWithStatistic.find((c: CustomerData) => c.id === customerIdFilter)
  const totalProjectsCount = React.useMemo<number>(() => {
    return selectedCustomer
      ? selectedCustomer.totalSites
      : customersWithStatistic.reduce((acc, cur) => {
          return acc + (cur.totalSites !== undefined ? cur.totalSites : 0)
        }, 0)
  }, [customersWithStatistic, selectedCustomer])

  const projectsWithLotViewer = React.useMemo<number>(() => {
    return selectedCustomer
      ? selectedCustomer.withLotViewer
      : customersWithStatistic.reduce((acc, cur) => {
          return acc + (cur.withLotViewer !== undefined ? cur.withLotViewer : 0)
        }, 0)
  }, [customersWithStatistic, selectedCustomer])

  const projectsWithSchedules = React.useMemo<number>(() => {
    return selectedCustomer
      ? selectedCustomer.withSchedules
      : customersWithStatistic.reduce((acc, cur) => {
          return acc + (cur.withSchedules !== undefined ? cur.withSchedules : 0)
        }, 0)
  }, [customersWithStatistic, selectedCustomer])

  if (!user) {
    return
  }

  if (!hasSitesWithLotViewer && !hasSitesWithSchedules) {
    return null
  }

  return (
    <div className={classes('statistic-block-container', statisticBlockContainerStyle)}>
      {hasSitesWithLotViewer && (
        <Pad
          className={'lot-viewer-statistic-pad'}
          icon={<PadIcon color={Gray100} />}
          isLoading={isLoading}
          header={t7e(i18nKeys.StatisticBlockTitleLotViewer)}
          totalNumber={totalProjectsCount}
          activeNumber={projectsWithLotViewer}
          onRequest={requestLotViewer}
        />
      )}
      {hasSitesWithSchedules && (
        <Pad
          className={'schedule-statistic-pad'}
          icon={<ScheduleIcon color={Gray100} />}
          isLoading={isLoading}
          header={t7e(i18nKeys.StatisticBlockTitleSchedule)}
          totalNumber={totalProjectsCount}
          activeNumber={projectsWithSchedules}
          onRequest={requestSchedule}
        />
      )}
    </div>
  )
}

interface PadProps {
  icon: React.ReactNode
  isLoading: boolean
  header: string
  totalNumber: number
  activeNumber: number
  onRequest: () => void
  className?: string
}

const Pad = ({ icon, header, totalNumber, activeNumber, className, onRequest, isLoading }: PadProps) => {
  return (
    <div className={classes('statistic-pad', className, padStyle, isLoading && padSkeletonStyle)}>
      {icon}
      <div className={classes('statistic-pad-text-info', textInfoStyle)}>
        <div className={classes('statistic-pad-text-info-header', textInfoHeaderStyle)}>{header}</div>
        <div
          className={classes('statistic-pad-text-info-main', textInfoBodyStyle)}
        >{`${activeNumber} of ${totalNumber}`}</div>
      </div>
      <Button
        icon={<PlusIcon color={Blue80} />}
        onClick={onRequest}
        styleType='white'
        text={t7e(i18nKeys.StatisticBlockAddMore)}
        className={classes(buttonStyle, 'add-more-button')}
      />
    </div>
  )
}

const statisticBlockContainerStyle = style(
  {
    maxHeight: 70,
    display: 'flex',
    gap: 12,
    marginBottom: 24,
    width: '100%'
  },
  media(
    { maxWidth: Breakpoint.SMALL_TABLET },
    {
      maxHeight: 'none'
    }
  ),
  media(
    { maxWidth: Breakpoint.MOBILE },
    {
      flexDirection: 'column'
    }
  )
)

export default StatisticBlock

const padStyle = style(
  {
    display: 'flex',
    backgroundColor: White,
    borderRadius: 16,
    border: `1px solid ${Gray20}`,
    boxShadow: '0px 9px 25px 0px rgba(0, 0, 0, 0.07), 0px 1.752px 3.13px 0px rgba(0, 0, 0, 0.04)',
    flex: 1,
    height: 70,
    alignItems: 'center',
    padding: '14px 16px 14px 24px',
    boxSizing: 'border-box'
  },
  media(
    { maxWidth: Breakpoint.SMALL_TABLET, minWidth: Breakpoint.MOBILE },
    {
      alignItems: 'flex-start',
      flexDirection: 'column',
      flexWrap: 'wrap',
      height: 100
    }
  )
)

const padSkeletonStyle = style({
  backgroundColor: `${Gray10} !important`,
  $nest: {
    '& > *': {
      display: 'none'
    }
  }
})

const textInfoStyle = style(
  {
    marginLeft: 16,
    fontFamily: 'Roboto',
    fontWeight: 500
  },
  media(
    { maxWidth: Breakpoint.SMALL_TABLET, minWidth: Breakpoint.MOBILE },
    {
      marginLeft: 0
    }
  )
)

const textInfoHeaderStyle = style(
  {
    fontSize: 10,
    lineHeight: '14px',
    color: Gray100
  },
  media(
    { maxWidth: Breakpoint.SMALL_TABLET, minWidth: Breakpoint.MOBILE },
    {
      marginTop: 8
    }
  )
)

const textInfoBodyStyle = style({
  fontSize: 20,
  lineHeight: '24px'
})

const buttonStyle = style(
  {
    marginLeft: 'auto'
  },
  media(
    { maxWidth: Breakpoint.SMALL_TABLET, minWidth: Breakpoint.MOBILE },
    {
      height: '100% !important'
    }
  )
)

const requestLotViewer = () => {
  track('Request Lot Viewer clicked')

  showUpsellPopup(UpsellPopupType.AddLotViewer)
}

const requestSchedule = () => {
  track('Request Schedule clicked')

  showUpsellPopup(UpsellPopupType.AddSchedules)
}
