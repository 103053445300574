import { InfluxTags, InfluxDB, InfluxDBPoint, InfluxField } from './InfluxDBWriter'
import AppStore from '../AppStore'

const metricsConfig: IMetricsConfig = METRICS // Comes from the server

export class TAMetricsWriter {
  private readonly unifiedMetricName: string
  private influxWriter: InfluxDB

  constructor(metricName: string) {
    this.unifiedMetricName = `${metricsConfig.componentName}/${metricName}`
    this.influxWriter = new InfluxDB(metricsConfig.influx.host, false)
  }

  addPoint(point: InfluxDBPoint) {
    point.setKey(this.unifiedMetricName)
    TAMetricsWriter.injectCommonTags(point)
    TAMetricsWriter.injectCommonFields(point)
    this.influxWriter.point(point)
  }

  async send() {
    const jwt = AppStore.getState().token
    if (!jwt || !metricsConfig.enabled) {
      return
    }
    this.influxWriter.send(jwt)
  }

  private static injectCommonTags(point: InfluxDBPoint) {
    const tags: InfluxTags = { ...point.getTags() }
    const { user } = AppStore.getState()
    if (!user) return

    tags['Environment'] = metricsConfig.environment
    tags['isTAEmployee'] = /@traceair\.net$/.test(user.email)
    tags['Email'] = user.email
    point.setTags(tags)
  }

  private static injectCommonFields(point: InfluxDBPoint) {
    const fields: InfluxField = { ...point.getFields() }
    const { user } = AppStore.getState()
    if (!user) return

    fields['Email'] = user.email

    point.setFields(fields)
  }
}
