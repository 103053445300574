import { ICurrentUser } from '../jwt-utils'
import AppStore from '../AppStore'

export function getUserTitle(user: ICurrentUser) {
  const { name, lastname, email } = user
  if (!name || !lastname) {
    return email
  }
  return name + ' ' + lastname
}

export function prefillUrlWithUserInfo(
  baseUrl: string,
  urlParamFn: (userInfo: ICurrentUser) => Record<string, string | undefined>
): string {
  const { user } = AppStore.getState()

  if (!user) {
    return baseUrl
  }

  const url = new URL(baseUrl)
  const params = urlParamFn(user)

  for (const [key, value] of Object.entries(params)) {
    if (value) {
      url.searchParams.set(key, value)
    }
  }

  return url.toString()
}
