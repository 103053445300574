import { AddScheduleIcon, DroneRequestIcon, PadIcon, PanoramaIcon } from '@traceair/webapp-icons'
import { GuideLink } from './GuideLink'
import { ActiveSite, UpsellPopupType } from './popupStore'
import { stringToInt } from '../../utils'
import { UpsellRequestType } from '../../services/OngoingProjectsApiService'
import { i18nKeys } from '../../i18n/i18nkeys'
import { t7e } from '../../i18n/i18n'

export function getUpsellProperties(activePopup: UpsellPopupType) {
  return {
    [UpsellPopupType.AddLotViewer]: {
      headerIcon: <PadIcon />,
      title: t7e(i18nKeys.UpsellPopupLotViewerTitle),
      description: (
        <>
          <div>{t7e(i18nKeys.UpsellPopupLotViewerDescription)}</div>
          <GuideLink activePopup={UpsellPopupType.AddLotViewer} />
        </>
      ),
      submitTitle: t7e(i18nKeys.UpsellPopupLotViewerTitle)
    },
    [UpsellPopupType.AddPanoramas]: {
      headerIcon: <PanoramaIcon />,
      title: t7e(i18nKeys.UpsellPopupPanosTitle),
      submitTitle: t7e(i18nKeys.UpsellPopupPanosTitle),
      counterLabel: t7e(i18nKeys.UpsellPopupPanosCountField)
    },
    [UpsellPopupType.AddSchedules]: {
      headerIcon: <AddScheduleIcon />,
      title: t7e(i18nKeys.UpsellPopupSchedulesTitle),
      description: (
        <>
          <div>{t7e(i18nKeys.UpsellPopupSchedulesDescription)}</div>
          <GuideLink activePopup={UpsellPopupType.AddSchedules} />
        </>
      ),
      submitTitle: t7e(i18nKeys.UpsellPopupSchedulesTitle)
    },
    [UpsellPopupType.RequestFlights]: {
      headerIcon: <DroneRequestIcon />,
      title: t7e(i18nKeys.UpsellPopupFlightsTitle),
      submitTitle: t7e(i18nKeys.UpsellPopupFlightsTitle),
      counterLabel: t7e(i18nKeys.UpsellPopupFlightsCountField)
    }
  }[activePopup]
}

export function getUpsellRequestType(activePopup: UpsellPopupType): UpsellRequestType {
  return {
    [UpsellPopupType.AddLotViewer]: 'lot-viewer' as const,
    [UpsellPopupType.AddPanoramas]: 'panos' as const,
    [UpsellPopupType.AddSchedules]: 'schedules' as const,
    [UpsellPopupType.RequestFlights]: 'flights' as const
  }[activePopup]
}

export function getSentText(activePopup: UpsellPopupType, activeSite?: ActiveSite, count?: string): string {
  let text = ''
  const counterInt = stringToInt(count) ?? 0

  switch (activePopup) {
    case UpsellPopupType.AddLotViewer:
      text = `, add Lot Viewer.`
      break
    case UpsellPopupType.AddPanoramas:
      text = `, add ${counterInt} panorama${counterInt > 1 ? 's' : ''}.`
      break
    case UpsellPopupType.AddSchedules:
      text = `, add Schedules.`
      break
    case UpsellPopupType.RequestFlights:
      text = `, add ${counterInt} flight${counterInt > 1 ? 's' : ''}.`
      break

    default:
      break
  }

  return `${activeSite?.name}${text}\nYour Account Manager will reach out shortly.`
}
