import { INavigationBarButtonProps } from '@traceair/tds'
import { DashboardAppLogoIcon } from '@traceair/webapp-icons'

export function createDashboardButton(): INavigationBarButtonProps | null {
  return {
    icon: DashboardAppLogoIcon,
    text: 'Dashboard',
    selected: true
  }
}
