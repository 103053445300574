import React from 'react'
import { classes, style } from 'typestyle'
import { Badge, BlackOpacity60 } from '@traceair/tds'

interface SideCardBadgesProps {
  formattedDate: string
  isLotViewerEnabled?: boolean
}

const SiteCardBadges: React.FC<SideCardBadgesProps> = ({ formattedDate, isLotViewerEnabled }) => {
  return (
    <div className={classes('scan-preview-badges-container', scanPreviewBadgesContainer)}>
      <Badge
        className={classes('scan-date-publish-day-badge', neutralBlackBadgeStyle)}
        styleType='blue'
        title={formattedDate}
      />
      {isLotViewerEnabled && <Badge className='scan-preview-lot-viewer-badge' title='Lot Viewer' styleType='purple' />}
    </div>
  )
}

export default SiteCardBadges

const neutralBlackBadgeStyle = style({
  background: BlackOpacity60,
  backdropFilter: 'blur(12.5px)'
})

const scanPreviewBadgesContainer = style({
  position: 'absolute',
  top: 8,
  left: 8,
  padding: 8,
  display: 'flex',
  gap: 8,
  zIndex: 1
})
