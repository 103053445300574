import { classes, media, style } from 'typestyle'
import React from 'react'
import BasicWidget from './cards/BasicWidget'
import VideoWidget from './cards/VideoWidget'
import { WIDGETS_MOCKUP_DATA } from './WidgetsData'
import PrimaryWidget from './cards/PrimaryWidget'
import { ButtonIcon } from '@traceair/tds'
import { ChevronLeftIcon, ChevronRightIcon } from '@traceair/webapp-icons'
import { WIDGET_CARD_WIDTH, WIDGET_CARDS_GAP } from './cards/styles'
import track from '../../Tracking'
import { Breakpoint, hideOnTabletsOrMobiles, showOnlyOnTabletsOrMobiles } from '../../responsive-breakpoints'

const Widgets = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0)
  const carouselRef = React.useRef<HTMLDivElement | null>(null)

  const handleCarouselScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setScrollPosition(e.currentTarget.scrollLeft)
  }

  const scrollCarousel = (scrollOffset: number) => {
    if (carouselRef.current) carouselRef.current.scrollLeft += scrollOffset
  }
  const maxScrollLeft = carouselRef.current ? carouselRef.current?.scrollWidth - carouselRef.current?.clientWidth : 0

  return (
    <div className={classes('dashboard-widgets', widgetsStyle)}>
      <div className={widgetsContainerStyle}>
        {WIDGETS_MOCKUP_DATA.primary && (
          <div className={hideOnTabletsOrMobiles}>
            <PrimaryWidget {...WIDGETS_MOCKUP_DATA.primary} />
          </div>
        )}
        <div ref={carouselRef} className={classes('carousel', carouselStyle)} onScroll={handleCarouselScroll}>
          {WIDGETS_MOCKUP_DATA.primary && (
            <div className={classes(showOnlyOnTabletsOrMobiles)}>
              <PrimaryWidget {...WIDGETS_MOCKUP_DATA.primary} />
            </div>
          )}
          {WIDGETS_MOCKUP_DATA.carousel.map(card => {
            if (card.type === 'basic' || card.type === 'basic-full-height') {
              return <BasicWidget key={card.header} {...card} isFullHeight={card.type === 'basic-full-height'} />
            }

            if (card.type === 'video') {
              return <VideoWidget key={card.header} {...card} />
            }

            return null
          })}
        </div>
      </div>

      <div className={classes(hideOnTabletsOrMobiles, style({ alignSelf: 'end' }))}>
        <div className={classes('carousel-buttons-container', carouselButtonsContainerStyle)}>
          <ButtonIcon
            onClick={() => {
              track('Scroll widgets carousel to the left')
              scrollCarousel(-cardScrollWidth)
            }}
            styleType='black-opacity'
            icon={ChevronLeftIcon}
            className={style({
              opacity: scrollPosition <= 0 ? 0.3 : 1
            })}
          />
          <ButtonIcon
            onClick={() => {
              track('Scroll widgets carousel to the right')
              scrollCarousel(cardScrollWidth)
            }}
            styleType='black-opacity'
            icon={ChevronRightIcon}
            className={style({
              opacity: maxScrollLeft && scrollPosition >= maxScrollLeft ? 0.3 : 1
            })}
          />
        </div>
      </div>
    </div>
  )
}

export default Widgets

const cardScrollWidth = WIDGET_CARD_WIDTH + WIDGET_CARDS_GAP

const widgetsStyle = style({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  maxHeight: 313,
  width: '100%',
  zIndex: 2,
  marginBottom: 24,
  overflowX: 'clip'
})

const carouselStyle = style(
  {
    borderRadius: 16,
    marginLeft: 8,
    display: 'flex',
    width: '100%',
    overflow: 'scroll',
    scrollBehavior: 'smooth',
    gap: WIDGET_CARDS_GAP,
    $nest: {
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }
  },
  media(
    { maxWidth: Breakpoint.TABLET },
    {
      scrollSnapType: 'x mandatory',
      maxWidth: '100%',
      borderRadius: 0,
      marginLeft: 0,
      $nest: {
        '&:last-child': { paddingRight: '40px' },
        '& > *': {
          scrollSnapAlign: 'center'
        }
      }
    }
  ),
  media({ maxWidth: Breakpoint.MOBILE }, { $nest: { '&:last-child': { paddingRight: '24px' } } }),
  media({ maxWidth: Breakpoint.MOBILE }, { $nest: { '&>:first-child': { marginLeft: '24px' } } }),
  media({ maxWidth: Breakpoint.TABLET }, { $nest: { '&>:first-child': { marginLeft: '40px' } } })
)

const carouselButtonsContainerStyle = style({
  alignSelf: 'end',
  display: 'flex',
  marginTop: 17,
  gap: 32
})

const widgetsContainerStyle = style({
  display: 'flex',
  marginLeft: 'auto',
  maxWidth: '100%'
})

export const openLinkInNewTab = (link: string) => {
  window.open(link, '_blank')
}

export const trackWidgetEvent = (msg: string) => {
  track(`Widget clicked: ${msg}`)
}
