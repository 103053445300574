export enum i18nKeys {
  Welcome,

  Project,
  Projects,
  AllProjects,
  Backoffice,
  Profile,
  Menu,
  SearchProject,
  Sites,
  SitesFound,
  NoSitesFound,
  AddNewProject,

  Total,
  Active,
  WithSchedules,
  WithLotViewer,
  PastDueMilestones,
  Archive,

  ActiveProjectState,
  ArchiveProjectState,

  AddProject,

  Options,
  NoScan,

  Pano,
  Panos,
  RequestPano,
  LastPano,
  LastPanos,

  ArchiveProject,
  UnarchieveProject,

  PickKeyMilestones,

  UserAdminWindowTitle,
  UserAdminButtonTitle,

  ManageProjects,

  SortBy,
  SiteName,
  ScanDate,
  ScheduleUpdated,
  ScheduleProgress,
  SortByNameDirect,
  SortByNameReverse,
  SortByTimeDirect,
  SortByTimeReverse,
  SortByProgressDirect,
  SortByProgressReverse,

  New,
  RequestNewScan,
  AddSchedules,
  AddLotViewer,

  StatisticBlockTitleLotViewer,
  StatisticBlockTitleSchedule,
  StatisticBlockAddMore,

  ProfileForm,
  ProfileFormEmail,
  ProfileFormName,
  ProfileFormLastName,
  ProfileFormPhone,
  ProfileFormPosition,
  ProfileFormChangePassword,
  ProfileFormSave,
  ProfileFormLogout,
  ProfileFormChangesSaved,
  TermsOfUse,
  Subscriptions,

  EXECUTIVE_DIRECTOR_VP,
  PROJECT_MANAGER,
  SENIOR_PROJECT_MANAGER,
  LAND_DEVELOPMENT_MANAGER,
  CONSTRUCTION_MANAGER,
  ENVIRONMENTAL,
  SWPPP_MANAGER,
  ASSISTANT_MANAGER,
  PURCHASING_AGENT_MANAGER,
  OTHER,
  EXECUTIVE_PRINCIPAL,
  PROJECT_ENGINEER,
  ESTIMATOR,
  SUPERINTENDENT,
  FOREMAN,
  GRADE_SETTER,
  SENIOR_ENGINEER,
  ASSOCIATE_ENGINEER,
  GEOLOGIST,
  GEOTECHNICAL_ENGINEER,
  HYDROLOGIST,
  CONSULTANT,
  ARCHITECT_URBAN_PLANNER,
  PILOT,
  SURVEYOR,
  SWPPP,
  ENVIRONMENTAL_CONSULTANT,
  CHIEF,
  HEAD,
  MANAGER,
  EXPERT,
  SPECIALIST,
  ENGINEER,
  DRONE_PILOT,

  CurrentPassword,
  NewPassHint,
  NewPassReEnterHint,
  NewPassLengthError,
  SavePassword,
  ChangesSaved,

  NewPassDoNotMatchError,
  NewPassRequiredError,
  NoEnteredNewPassError,
  CurrentPassRequired,
  WrongEnteredCurrentPassError,

  ButtonCancel,
  ButtonClose,

  UpsellPopupLotViewerTitle,
  UpsellPopupLotViewerDescription,
  UpsellPopupPanosTitle,
  UpsellPopupPanosCountField,
  UpsellPopupSchedulesTitle,
  UpsellPopupSchedulesDescription,
  UpsellPopupFlightsTitle,
  UpsellPopupFlightsCountField,

  UpsellPopupGuideLink,
  UpsellPopupFieldPlaceholderAccount,
  UpsellPopupFieldLabelAccount,
  UpsellPopupFieldPlaceholderSite,
  UpsellPopupFieldLabelSite,
  UpsellPopupFieldPlaceholderComment,
  UpsellPopupNetworkError,
  UpsellPopupSuccess
}

export type literals = { [key in i18nKeys]: ((...arg: string[]) => string) | string }
