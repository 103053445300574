import { stylesheet } from 'typestyle'
import { colorToRgba, White } from '@traceair/tds'

export const WIDGET_CARD_BORDER_RADIUS = 16
export const WIDGET_CARD_HEIGHT = 260
export const WIDGET_CARD_WIDTH = 330
export const WIDGET_CARDS_GAP = 8

export const WidgetStyles = stylesheet({
  descriptionTextStyle: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '18px',
    marginTop: 8,
    letterSpacing: '-0.5px',
    whiteSpace: 'pre-wrap'
  },
  headerTextStyle: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0px',
    whiteSpace: 'pre-wrap'
  },
  containerStyle: {
    width: WIDGET_CARD_WIDTH,
    height: WIDGET_CARD_HEIGHT,
    borderRadius: WIDGET_CARD_BORDER_RADIUS,
    boxShadow: `0 0 1px ${colorToRgba(White, 0.06)} inset`,
    boxSizing: 'border-box',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    justifyContent: 'end',
    background: 'linear-gradient(180deg, #353538 0%, #222224 100%)',
    flexShrink: 0
  },
  infoContainerStyle: {
    zIndex: 2,
    marginLeft: 16,
    marginBottom: 20,
    color: White,
    fontFamily: 'Roboto'
  },
  baseImageStyle: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  infoStyle: {
    marginTop: 12
  }
})

export const WidgetBasicFullHeightStyles = stylesheet({
  containerStyle: {
    justifyContent: 'stretch',

    $nest: {
      [`& .${WidgetStyles.infoContainerStyle}`]: {
        flexGrow: 1,

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      },

      [`& .${WidgetStyles.infoStyle}`]: {
        order: 0
      },

      [`& .widget-card-tags-container`]: {
        order: 1
      }
    }
  }
})
