import axios, { AxiosResponse } from 'axios'
import { sessionIdInterceptor } from './SessionIDInterceptor'
import { ICurrentUser } from '../jwt-utils'
import { IUpdatedUserData } from '../AppStore'
import { CustomerId, ProjectActivity, Site, SiteId } from '../types'
import { tokenInterceptor } from './TokenInterceptor'

const CSUApi = axios.create({
  baseURL: CSUAPIBaseUrl
})

CSUApi.interceptors.request.use(config => sessionIdInterceptor(config))
CSUApi.interceptors.request.use(config => tokenInterceptor(config))

export function setSiteProjectActivity(customerId: CustomerId, siteId: SiteId, projectActivity: ProjectActivity) {
  return CSUApi.put(`api/${CSUAPIVersion}/customers/${customerId}/sites/${siteId}/set-activity-status`, {
    projectActivity
  }).then((response: AxiosResponse<{ site: Site }>) => {
    const { site } = response.data
    site.customerId = customerId
    return site
  })
}

export async function updateSelf(user: IUpdatedUserData): Promise<ICurrentUser> {
  try {
    const res = await CSUApi.put(`api/${CSUAPIVersion}/users/self`, { user })
    return res.data.user
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getCustomerTypes(): Promise<{ key: string; titles: string[] }[]> {
  try {
    const res = await CSUApi.get(`api/${CSUAPIVersion}/customers-types`)
    return res.data.customerTypes
  } catch (error) {
    console.error(error)
    throw error
  }
}
