import React from 'react'
import { ButtonIcon } from '@traceair/tds'
import { ChatIcon } from '@traceair/webapp-icons'
import { classes, style } from 'typestyle'
import track from '../Tracking'

interface IHelpButtonProps {
  className?: string
  iconColor?: string
}

const HelpButton: React.FC<IHelpButtonProps> = ({ className, iconColor }) => {
  const showIntercomWindow = () => {
    window.Intercom('update', {
      hide_default_launcher: true
    })
    window.Intercom('show')
  }

  return (
    <ButtonIcon
      icon={<ChatIcon color={iconColor} />}
      className={classes(btnStyle, className)}
      onClick={() => {
        track('Click on Help button')
        showIntercomWindow()
      }}
    />
  )
}
export default HelpButton

const btnStyle = style({
  borderRadius: 12,
  height: 40,
  width: 40
})
