import React from 'react'
import { Blue80, Gray20 } from '@traceair/tds'

interface CircularProgressProps {
  value: number
}

const CircularProgress: React.FC<CircularProgressProps> = ({ value }) => {
  const viewSize = 40 // SVG view size
  const strokeWidth = 3
  // Adjust the radius so that the circle touches the edges of the SVG view box
  const radius = (viewSize - strokeWidth) / 2
  const circumference = radius * 2 * Math.PI
  const strokeDashoffset = circumference - (value / 100) * circumference

  return (
    <svg
      height={viewSize}
      width={viewSize}
      viewBox={`0 0 ${viewSize} ${viewSize}`}
      style={{ minHeight: 40, minWidth: 40 }}
    >
      {/* Gray background circle */}
      <circle
        stroke={Gray20}
        fill='transparent'
        strokeWidth={strokeWidth}
        strokeDasharray={circumference + ' ' + circumference}
        r={radius}
        cx={viewSize / 2}
        cy={viewSize / 2}
      />
      {/* Blue progress circle with rounded ends */}
      <circle
        stroke={Blue80}
        fill='transparent'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        r={radius}
        cx={viewSize / 2}
        cy={viewSize / 2}
        transform={`rotate(-90 ${viewSize / 2} ${viewSize / 2})`}
      />
      {/* Text in the center */}
      <text
        className='schedule-progress-value'
        x='50%'
        y='50%'
        textAnchor='middle'
        dominantBaseline='central'
        fill='#000'
        style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: 12, lineHeight: '14px', letterSpacing: -0.25 }}
      >
        {`${value}%`}
      </text>
    </svg>
  )
}

export default CircularProgress
