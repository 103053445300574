import { AnalyticsUtilsAbstract, SiteParams, Subsystem } from 'browser-tracking-lib'
import { IUacBackendInfo } from 'browser-tracking-lib/browser_tracking_lib/dist/analytics/AnalyticsUtilsAbstract'
import { UserInfo } from 'browser-tracking-lib/browser_tracking_lib/dist/analytics/contract'
import { ICurrentUser } from './jwt-utils'

const { enabled, backendUrl, environment, location } = Analytics
const appVersion = process.env.REACT_APP_VERSION

let tracker: Tracking | undefined
export function initTracker(userInfo: ICurrentUser, customerNames: string[]) {
  if (tracker) {
    return
  }
  tracker = new Tracking(userInfo, customerNames)
}

export default function track(eventMsg?: string, params?: object) {
  console.log(eventMsg)
  if (!enabled) {
    return
  }
  if (!tracker) {
    throw new Error('tracker is not initialized')
  }
  tracker.track(eventMsg, params)
}

export class Tracking extends AnalyticsUtilsAbstract {
  private userInfo?: UserInfo
  constructor(userInfo: ICurrentUser, customerNames: string[]) {
    super()
    if (!enabled) {
      return
    }

    this.setSessionId()
    this.updateUserInfo(userInfo, customerNames)
  }

  protected async getBackendInfo(): Promise<IUacBackendInfo> {
    return { url: `${backendUrl}/api/store-action` }
  }

  protected isEnabled(): boolean {
    return enabled
  }

  updateUserInfo = (userInfo: ICurrentUser, customerNames: string[]) => {
    if (userInfo && this.isEnabled()) {
      this.setUserIdCookie(userInfo.id)

      this.userInfo = {
        userId: userInfo.id || '',
        email: userInfo.email,
        name: userInfo.name,
        title: userInfo.title,
        company: userInfo.company ? userInfo.company.name : '',
        companyId: userInfo.company ? userInfo.company.id : '',
        department: userInfo.department || '',
        companyType: userInfo.company ? userInfo.company.type : '',
        titleDescription: userInfo.titleDescription || '',
        phone: userInfo.phone || '',
        lang: userInfo.lang,
        customers: customerNames,
        lastname: userInfo.lastname,
        createdTime: strDateToTimestamp(userInfo.createdAt),
        updatedTime: strDateToTimestamp(userInfo.updatedAt),
        location
      }
    }
  }

  getUserInfo(): UserInfo | undefined {
    return this.userInfo
  }

  isTAUser(): boolean {
    return !!this.userInfo && !!this.userInfo.email && this.userInfo.email.indexOf('@traceair.net') > -1
  }

  protected getSiteParams(): SiteParams {
    return {
      site: '',
      siteName: '',
      customer: ''
    }
  }

  getAppParams() {
    return {
      componentVersion: appVersion as string,
      component: 'Dashboard',
      componentData: {},
      subsystem: 'FO' as Subsystem,
      env: environment
    }
  }

  async track(eventMsg?: string, params?: object): Promise<void> {
    if (this.isTAUser()) {
      return
    }
    return super.track('(Dashboard) ' + eventMsg, {
      ...params
    })
  }
}

function strDateToTimestamp(date?: string): number | undefined {
  return date ? new Date(date).getTime() : undefined
}
