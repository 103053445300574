import { media, style } from 'typestyle'

export enum Breakpoint {
  TABLET = 1199,
  SMALL_TABLET = 767,
  MOBILE = 599,
  SMALL_MOBILE = 479
}

const hideAbove = (breakpoint: Breakpoint) => style(media({ minWidth: breakpoint + 1 }, { display: 'none' }))
const hideBelow = (breakpoint: Breakpoint) => style(media({ maxWidth: breakpoint }, { display: 'none' }))

export const showOnlyOnTabletsOrMobiles = hideAbove(Breakpoint.TABLET)
export const hideOnTabletsOrMobiles = hideBelow(Breakpoint.TABLET)

export const hideOnSmallTabletsOrMobiles = hideBelow(Breakpoint.SMALL_TABLET)

export const showOnlyOnMobiles = hideAbove(Breakpoint.MOBILE)
export const hideOnMobiles = hideBelow(Breakpoint.MOBILE)
