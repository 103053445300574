import * as Sentry from '@sentry/react'

export function handleImageLoadErrors() {
  // Global error event listener
  window.addEventListener(
    'error',
    function (event) {
      if ((event.target as HTMLImageElement)?.tagName === 'IMG') {
        const img = event.target as HTMLImageElement

        const tokenParamRegExp = /access_token=([^&]+)/
        const tokenParamUrlMatch = img.src.match(tokenParamRegExp)

        const errorDetails = {
          // Removing token to prevent leaking access token and filtering by Sentry of the whole URL
          src: img.src.replace(tokenParamRegExp, '[redacted]'),
          // Also not using `token` as key name to prevent Sentry filtering
          tLength: tokenParamUrlMatch ? tokenParamUrlMatch[1].length : undefined,
          alt: img.alt
        }

        Sentry.captureMessage('Image load error', {
          level: 'warning',
          extra: errorDetails
        })
      }
    },
    true // Using capture phase to use this event listener before any other
  )
}
