import { updateSelf } from './services/CSUApiService'

import { subscribeWithSelector } from 'zustand/middleware'
import { create } from 'zustand'
import { decodeJWT, IAcl, ICurrentUser, JWTToken } from './jwt-utils'
import { ReactNode } from 'react'
import {
  CustomerData,
  CustomerId,
  CustomerWithStatistic,
  Filters,
  Site,
  SiteData,
  Sorting,
  SortingDirection,
  SortingOrders
} from './types'
import { StatisticResponseData } from './services/CustomerDashboardApiService'

export type CustomerTokenInfo = { customerId: CustomerId; token: JWTToken; acl: IAcl }

export interface IUpdatedUserData extends Omit<ICurrentUser, 'company'> {
  company?: string | { id: string; name: string; type: string }
}

export type CustomerIdFilter = CustomerId | null
export type CustomersToken = Record<CustomerId, CustomerTokenInfo>

export type AppStoreState = {
  token: JWTToken | undefined
  siteViewToken: JWTToken | undefined
  user: ICurrentUser | undefined
  acl: IAcl
  customers: CustomerData[]
  customersWithStatistic: CustomerWithStatistic[]
  sites: SiteData[]
  totalSites: number | null
  hasSitesWithLotViewer: boolean
  hasSitesWithSchedules: boolean
  window: ReactNode | undefined
  customerIdFilter: CustomerIdFilter
  siteNameFilterString: string
  customersDataLoading: boolean
  sitesDataLoading: boolean
  filters: Filters | undefined
  sorting: Sorting
  loadedSitesSkipCount: number
}

type Actions = {
  actions: {
    setCustomersDataLoading: () => void
    setCustomersDataLoaded: (customers: CustomerData[]) => void
    setSitesDataLoading: () => void
    setSitesDataLoaded: (sites: SiteData[]) => void
    clearSitesInfo: () => void
    updateToken: (tokens: JWTToken) => void
    setSiteViewToken: (tokens: JWTToken) => void
    setUserInfo: (user: ICurrentUser) => void
    setSiteProjectActivity: (site: Site) => void
    setCustomerFilterId: (customerIdFilter: CustomerIdFilter) => void
    setSiteNameFilterString: (siteNameFilterString: string) => void
    showWindow: (window: ReactNode) => void
    hideWindow: () => void
    setMilestoneVisibility: (milestoneId: string, isKey: boolean, siteId: string, customerId: string) => void
    setMilestoneUpdatePending: (milestoneId: string, siteId: string, customerId: string, pending: boolean) => void
    setFilters: (filters: Partial<Filters>) => void
    setSorting: (sorting: Sorting) => void
    setTotalSites: (newCount: number) => void
    setHasSitesWithLotViewer: (hasSitesWithLotViewer: boolean) => void
    setHasSitesWithSchedules: (hasSitesWithSchedules: boolean) => void
    updateUserInfo: (user: ICurrentUser) => void
    setLoadedSitesSkipCount: (skipCount: number) => void
    setCustomerStatistic: (statistic: StatisticResponseData[]) => void
  }
}

type Helpers = {
  helpers: {
    getSites: (filterByCustomerIdFilter: boolean, filterBySiteNameFilterString: boolean) => SiteData[]
  }
}

export const initialFilters: Filters = {
  active: true,
  withSchedule: false,
  withLotViewer: false,
  pastDueMilestones: false,
  archived: false
}

export const defaultDirections: Record<SortingOrders, SortingDirection> = {
  [SortingOrders.SiteName]: SortingDirection.Asc,
  [SortingOrders.ScanDate]: SortingDirection.Desc,
  [SortingOrders.ScheduleUpdated]: SortingDirection.Desc,
  [SortingOrders.ScheduleProgress]: SortingDirection.Desc
}

const defaultSortingKey: SortingOrders = SortingOrders.SiteName

const getSortingWithDefaultDirection = (key: SortingOrders) => ({
  key,
  direction: defaultDirections[key]
})

export type AppStoreType = AppStoreState & Actions & Helpers

const useAppStore = create<AppStoreType>()(
  subscribeWithSelector((set, get) => ({
    loadedSitesSkipCount: 0,
    token: undefined,
    siteViewToken: undefined,
    user: undefined,
    acl: {
      systemLevel: [],
      customerLevel: {},
      siteLevel: {}
    },
    customers: [],
    customersWithStatistic: [],
    sites: [],
    totalSites: null,
    hasSitesWithLotViewer: false,
    hasSitesWithSchedules: false,
    window: undefined,
    customerIdFilter: null,
    siteNameFilterString: '',
    customersDataLoading: false,
    sitesDataLoading: true,
    filters: undefined,
    sorting: getSortingWithDefaultDirection(defaultSortingKey),
    actions: {
      setCustomersDataLoading: () => set({ customersDataLoading: true }),
      setCustomersDataLoaded: customers => set({ customers }),
      setSitesDataLoading: () => set({ sitesDataLoading: true }),
      setSitesDataLoaded: addedSites => {
        const newSites = get().sites.concat(addedSites)
        set({ sitesDataLoading: false, sites: newSites })
      },
      clearSitesInfo: () => set({ sites: [], loadedSitesSkipCount: 0 }),
      updateToken: (token: JWTToken) => {
        const tokenPayload = decodeJWT(token)
        set(() => ({ acl: tokenPayload.ACL, token, user: tokenPayload.user }))
      },
      setSiteViewToken: (siteViewToken: JWTToken) => {
        set(() => ({ siteViewToken }))
      },
      setUserInfo: user => set({ user }),
      setSiteProjectActivity: site => {
        set(() => {
          const sites: SiteData[] = get().sites
          const updatedSites = sites.slice()
          const siteIndex = sites.findIndex(s => s.siteId === site.id && s.customerId === site.customerId)
          updatedSites[siteIndex].projectActivity = site.projectActivity
          return { sites: updatedSites }
        })
      },
      setCustomerFilterId: customerIdFilter => set({ customerIdFilter }),
      setSiteNameFilterString: siteNameFilterString => {
        set(() => {
          return {
            siteNameFilterString
          }
        })
      },
      showWindow: window => set({ window }),
      hideWindow: () => set({ window: undefined }),
      setMilestoneVisibility: (milestoneId: string, isKey: boolean, siteId: string, customerId: string) => {
        set(() => {
          const sites: SiteData[] = get().sites
          const updatedSites = sites.slice()
          const siteIndex = sites.findIndex(s => s.siteId === siteId && s.customerId === customerId)

          const updatedMilestones = sites[siteIndex].schedule!.milestones.map(milestone => {
            if (milestone.id === milestoneId) {
              return {
                ...milestone,
                isKey
              }
            }
            return milestone
          })

          updatedSites[siteIndex].schedule = {
            ...sites[siteIndex].schedule!,
            milestones: updatedMilestones
          }

          return { sites: updatedSites }
        })
      },
      setMilestoneUpdatePending: (customerId: string, siteId: string, milestoneId: string, pending: boolean) => {
        set(() => {
          const sites: SiteData[] = get().sites
          const updatedSites = sites.slice()
          const siteIndex = sites.findIndex(s => s.siteId === siteId && s.customerId === customerId)

          const updatedMilestones = sites[siteIndex].schedule!.milestones.map(milestone => {
            if (milestone.id === milestoneId) {
              return {
                ...milestone,
                pending
              }
            }
            return milestone
          })

          updatedSites[siteIndex].schedule = {
            ...sites[siteIndex].schedule!,
            milestones: updatedMilestones
          }

          return { sites: updatedSites }
        })
      },
      setTotalSites: totalSites => set({ totalSites }),
      setHasSitesWithLotViewer: hasSitesWithLotViewer => set({ hasSitesWithLotViewer }),
      setHasSitesWithSchedules: hasSitesWithSchedules => set({ hasSitesWithSchedules }),
      setFilters: changedFilters => {
        set(() => {
          const filters = get().filters || initialFilters
          const updatedFilters = { ...filters, ...changedFilters }
          return { filters: updatedFilters }
        })
      },
      updateUserInfo: async (user: ICurrentUser) => {
        const copyUserData: IUpdatedUserData = { ...user }

        if (user.company && user.company.id) {
          copyUserData.company = user.company.id
        }
        const updatedUser = await updateSelf(copyUserData)

        set({ user: updatedUser })
      },
      setSorting: sorting => set({ sorting }),
      setLoadedSitesSkipCount: skipCount => set({ loadedSitesSkipCount: skipCount }),
      setCustomerStatistic: statistic => {
        const customers = get().customers
        const updatedCustomers: CustomerWithStatistic[] = []
        statistic.forEach(customerStat => {
          const statCustomerIndex = customers.findIndex(c => c.id === customerStat.customerId)
          const { totalSites, withSchedules, withPastDueMilestones, withLotViewer } = customerStat
          updatedCustomers.push({
            ...customers[statCustomerIndex],
            totalSites,
            withSchedules,
            withPastDueMilestones,
            withLotViewer
          })
        })
        set({ customersDataLoading: false, customersWithStatistic: updatedCustomers })
      }
    },
    helpers: {
      getSites: (filterByCustomerIdFilter = true, filterBySiteNameFilterString = true) => {
        const { customerIdFilter, siteNameFilterString, sites } = useAppStore.getState()
        let filteredSites: SiteData[] = []
        if (filterByCustomerIdFilter && customerIdFilter) {
          filteredSites = sites.filter((site: SiteData) => site.customerId === customerIdFilter)
        }

        if (filterBySiteNameFilterString) {
          filteredSites = sites.filter((site: SiteData) =>
            site.name.toLowerCase().includes(siteNameFilterString.toLowerCase())
          )
        }
        return filteredSites
      }
    }
  }))
)

export default useAppStore
