import React from 'react'
import { classes, style } from 'typestyle'
import { Menu, MenuItem, boxShadow, Black } from '@traceair/tds'
import {
  AddScheduleIcon,
  DroneRequestIcon,
  FlagIcon,
  FolderArchiveEmptyIcon,
  PadIcon,
  PanoramaIcon,
  UserGroupIcon
} from '@traceair/webapp-icons'
import MilestonesMenu from './MilestonesMenu'
import { allowToChangeProjectStatus, allowToManageUsersOnSite, allowToPickMilestones } from './helpers'
import useAppStore from '../../../../AppStore'
import { t7e } from '../../../../i18n/i18n'
import { i18nKeys } from '../../../../i18n/i18nkeys'
import track from '../../../../Tracking'
import { CustomerData, ProjectActivity, SiteData } from '../../../../types'
import { setSiteProjectActivity } from '../../../../services/CSUApiService'
import { hideOnSmallTabletsOrMobiles } from '../../../../responsive-breakpoints'
import { UpsellPopupType, showUpsellPopup } from '../../../upsell_popup'

interface ContextMenuProps {
  customer: CustomerData
  site: SiteData
}

function showUMAWindow(site: SiteData) {
  const umaWindow = (
    <div className={manageUsersFormDialogStyle}>
      <iframe frameBorder='0' title={'User Management App'} width='100%' height='100%' src={composeUmaUrl(site)} />
    </div>
  )

  useAppStore.getState().actions.showWindow(umaWindow)
  track('Manage users window is opened')
  window.addEventListener('message', handleWindowEventReceived)
}

function closeUMAWindow() {
  useAppStore.getState().actions.hideWindow()
  window.removeEventListener('message', handleWindowEventReceived)
}

function composeUmaUrl(site: SiteData) {
  return `${UserManagementAppUrl}/?customer=${site.customerId}&siteName=${site.name}&site=${
    site.siteId
  }&loginServiceBaseUrl=${encodeURIComponent(LoginServiceBaseUrl)}&title=${t7e(
    i18nKeys.UserAdminWindowTitle,
    site.name
  )}`
}

function handleWindowEventReceived(event: MessageEvent) {
  if (event.data === 'UMA_APP_CLOSE') {
    track('Manage users window is closed')
    closeUMAWindow()
  }
}

function ContextMenu({ customer, site }: ContextMenuProps) {
  const user = useAppStore(state => state.user)
  const actions = useAppStore(state => state.actions)
  const acl = useAppStore(state => state.acl)
  const [showMilestonesMenu, setShowMilestonesMenu] = React.useState(false)

  if (!user) {
    return
  }

  const hasPermissionToPickMilestones = allowToPickMilestones(acl, user, site)
  const hasPermissionToManageUsers = allowToManageUsersOnSite(acl, site)

  if (showMilestonesMenu) {
    return (
      <div className={classes('site-card-context-menu-container', siteCardContextContainer)}>
        <MilestonesMenu customer={customer} site={site} />
      </div>
    )
  }

  const upsellPopupParams = {
    customerId: site.customerId,
    id: site.siteId,
    name: site.name
  }

  function requestLotViewer() {
    track('Request Lot Viewer clicked')

    showUpsellPopup(UpsellPopupType.AddLotViewer, upsellPopupParams)
  }

  function requestSchedule() {
    track('Request Schedule clicked')

    showUpsellPopup(UpsellPopupType.AddSchedules, upsellPopupParams)
  }

  function requestNewPano() {
    track('Request Pano Button clicked')

    showUpsellPopup(UpsellPopupType.AddPanoramas, upsellPopupParams)
  }

  function requestNewScan() {
    track('Request Scan clicked')

    showUpsellPopup(UpsellPopupType.RequestFlights, upsellPopupParams)
  }

  async function handleArchiveProjectClick() {
    track('Update Project Activity')
    const updatedSite = await setSiteProjectActivity(
      site.customerId,
      site.siteId,
      site.projectActivity === ProjectActivity.Active ? ProjectActivity.Archived : ProjectActivity.Active
    )

    actions.setSiteProjectActivity(updatedSite)
  }

  return (
    <div className={classes('site-card-context-menu-container', siteCardContextContainer)}>
      <Menu>
        {site.schedule && hasPermissionToPickMilestones && (
          <MenuItem
            icon={<FlagIcon color={Black} />}
            title={'Pick milestones'}
            onClick={e => {
              track('Show milestones menu Click')
              e.stopPropagation()
              setShowMilestonesMenu(true)
            }}
          />
        )}
        {hasPermissionToManageUsers && (
          <MenuItem
            className={hideOnSmallTabletsOrMobiles}
            icon={<UserGroupIcon color={Black} />}
            title={'Manage Users'}
            onClick={() => {
              track('Show UMA window Click')
              showUMAWindow(site)
            }}
          />
        )}

        <MenuItem
          className='site-card-menu-request-lot-viewer'
          icon={<PadIcon color={Black} />}
          title={t7e(i18nKeys.AddLotViewer)}
          onClick={requestLotViewer}
        />
        <MenuItem
          className='site-card-menu-request-schedule'
          icon={<AddScheduleIcon color={Black} />}
          title={t7e(i18nKeys.AddSchedules)}
          onClick={requestSchedule}
        />
        <MenuItem
          className='site-card-menu-request-new-panorama'
          icon={<PanoramaIcon color={Black} />}
          title={t7e(i18nKeys.RequestPano)}
          onClick={requestNewPano}
        />
        <MenuItem
          className='site-card-menu-request-new-scan'
          icon={<DroneRequestIcon color={Black} />}
          title={t7e(i18nKeys.RequestNewScan)}
          onClick={requestNewScan}
        />
        {allowToChangeProjectStatus(user, acl, site.customerId, site.siteId) && (
          <MenuItem
            className='change-project-status-button'
            icon={<FolderArchiveEmptyIcon color={Black} />}
            title={
              site.projectActivity === ProjectActivity.Active
                ? t7e(i18nKeys.ArchiveProject)
                : t7e(i18nKeys.UnarchieveProject)
            }
            onClick={handleArchiveProjectClick}
          />
        )}
      </Menu>
    </div>
  )
}

export default ContextMenu

const siteCardContextContainer = style({
  zIndex: 7,
  position: 'absolute',
  top: 54,
  right: 16,
  minHeight: 168,
  width: 220
})

const manageUsersFormDialogStyle = style({
  width: 720,
  height: 626,
  borderRadius: 12,
  overflow: 'hidden',
  boxShadow: boxShadow
})
