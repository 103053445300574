import React from 'react'
import { TextInput } from '@traceair/tds'
import { style } from 'typestyle'
import { VisibilityOffIcon, VisibilityOnIcon } from '@traceair/webapp-icons'

interface IPasswordInputProps {
  onChange: (value: string) => void
  value: string
  placeholder: string
  title: string
  errorMessage: string | undefined
  isInvalid: boolean
  shouldBeFocused?: boolean
  onBlur?: () => void
  onFocus?: () => void
  showLengthAlert?: boolean
}

const PasswordInput = ({
  onChange,
  value,
  placeholder,
  title,
  errorMessage,
  isInvalid,
  shouldBeFocused,
  onBlur,
  onFocus
}: IPasswordInputProps) => {
  const [showPassword, setShowPassword] = React.useState(false)
  const [isFocused, setFocus] = React.useState(shouldBeFocused || false)

  return (
    <div
      onBlur={() => {
        setFocus(false)
        onBlur && onBlur()
      }}
      onFocus={() => {
        setFocus(true)
        onFocus && onFocus()
      }}
      onTouchStart={() => {
        setFocus(true)
      }}
    >
      <TextInput
        onChange={value => {
          onChange && onChange(value)
        }}
        type={showPassword ? 'text' : 'password'}
        value={value}
        placeholder={placeholder}
        label={title}
        rightIcon={
          <button
            tabIndex={-1}
            onClick={() => {
              setShowPassword(!showPassword)
            }}
          >
            <div className={style({ visibility: isFocused ? 'visible' : 'hidden' })}>
              {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
            </div>
          </button>
        }
        errorMessage={errorMessage ? errorMessage : undefined}
        isError={isInvalid}
      />
    </div>
  )
}

export default PasswordInput
