import * as React from 'react'

const LogoIcon = () => {
  return (
    <svg width='121' height='20' viewBox='0 0 121 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.422 6.48315V8.15552H35.0894V16.5365H33.2089V8.15552H28.8765V6.48315H39.422Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M47.7657 6.48315H41.7653V16.5365H43.6486V8.17363H47.6921C49.0952 8.17363 50.0553 9.01004 50.0553 10.3734C50.0553 11.7187 49.0952 12.5187 47.6921 12.5187H44.6319V14.2095H48.0614L49.3537 16.5365H51.4032L49.7969 13.7552C51.0709 13.1551 51.9202 11.9732 51.9202 10.3734C51.9202 8.0283 50.1109 6.48315 47.7657 6.48315Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M116.311 6.48315H110.311V16.5365H112.194V8.17363H116.237C117.641 8.17363 118.601 9.01004 118.601 10.3734C118.601 11.7187 117.641 12.5187 116.237 12.5187H113.174V14.2095H116.607L117.899 16.5365H119.949L118.342 13.7552C119.616 13.1551 120.466 11.9732 120.466 10.3734C120.466 8.0283 118.656 6.48315 116.311 6.48315Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M98.7639 6.48315H95.233L90.7826 16.5365H92.7873L96.3732 8.13753H97.5319L99.6649 13.3185H95.0681L94.3952 14.9548H100.419L101.118 16.5365H103.086L98.7639 6.48315Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M61.2688 6.48315H57.7379L53.2875 16.5365H55.2922L58.8781 8.13753H60.0367L62.1698 13.3185H57.5729L56.9001 14.9548H62.9239L63.6228 16.5365H65.5905L61.2688 6.48315Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M75.8312 9.61993C75.0193 8.53977 73.8751 7.92791 72.1956 7.92791C69.9072 7.92791 68.431 9.33182 68.431 11.5099C68.431 13.6159 69.9626 15.0919 72.1956 15.0919C73.8935 15.0919 74.9823 14.4798 75.8312 13.3817L77.3077 14.2278C76.2373 15.8298 74.5579 16.7298 72.2327 16.7298C68.9847 16.7298 66.5669 14.7317 66.5669 11.5099C66.5669 8.27004 69.0398 6.28979 72.2327 6.28979C74.5211 6.28979 76.2373 7.17195 77.2891 8.79168L75.8312 9.61993Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M81.5256 8.15541V14.8457H89.0248V16.5365H79.651V6.48315H88.7493V8.15541H81.5256ZM86.3882 12.1845H82.5801V10.5798H86.3882V12.1845Z'
        fill='white'
      />
      <path fillRule='evenodd' clipRule='evenodd' d='M105.233 16.5365H107.186V6.48315H105.233V16.5365Z' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.88311 14.1059H0.169189V3.52734H15.128L15.5889 4.44997L15.128 5.36893H2.0313V12.2643H7.74522V17.7328H15.1523V12.2643H20.8661V7.29483L22.7282 6.38021V14.1059H17.0144V19.5744H5.88311V14.1059Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.35 4.44415L15.2284 0.447754L17.2386 4.44415L15.2284 8.44054L23.35 4.44415Z'
        fill='#007AFF'
      />
    </svg>
  )
}

export default LogoIcon
