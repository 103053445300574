import React from 'react'
import { classes, style } from 'typestyle'
import Trobber from '../icons/throbber.gif'

interface IThrobberProps {
  show?: boolean
  className?: string
  children?: React.ReactNode
}

const Throbber: React.FC<IThrobberProps> = ({ show, className, children }) => {
  return (
    <div className={classes(throbberContainer, show ? 'show-throbber' : '', className)}>
      {show && (
        <div className='throbber-shown-container'>
          <img src={Trobber} className='throbber-image' alt='Throbber' />
        </div>
      )}
      <div className='throbber-content'>{children}</div>
    </div>
  )
}

export default Throbber

const throbberContainer = style({
  position: 'relative',
  $nest: {
    '&.show-throbber': {
      $nest: {
        ['& .throbber-content']: {
          visibility: 'hidden'
        },
        [`& .throbber-shown-container`]: {
          position: 'absolute',
          display: 'flex',
          top: 0,
          left: 0,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          $nest: {
            ['& .throbber-image']: {
              display: 'block',
              height: 60,
              width: 80
            }
          }
        }
      }
    }
  }
})
