import React from 'react'

import { classes, style } from 'typestyle'
import { type14Medium, TextItem, White } from '@traceair/tds'
import { JWTToken } from '../../../../jwt-utils'
import urljoin from 'url-join'
import { format, parseISO } from 'date-fns'

interface PanoPreviewProps {
  previewPath: string
  doneDate: string
  token: JWTToken
}

const PanoPreview: React.FC<PanoPreviewProps> = ({ previewPath, doneDate, token }) => {
  if (previewPath === null) return null

  return (
    <div className={classes('pano-preview-container', panoramaPreviewContainer)}>
      <img
        crossOrigin='anonymous'
        src={buildPanoramaPreviewUrl(previewPath, token)}
        className={panoramaImageStyle}
        alt='Panorama preview'
      />
      <div
        className={style({
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          zIndex: 2
        })}
      >
        <div
          className={style({
            display: 'flex',
            justifyContent: 'center',
            color: White,
            paddingBottom: 22
          })}
        >
          <TextItem text={formatPanoDate(doneDate)} style={type14Medium} />
        </div>
      </div>
    </div>
  )
}

export default PanoPreview

function buildPanoramaPreviewUrl(previewPath: string, token: JWTToken) {
  return urljoin(FOStorage.url, FOStorage.panosPathPrefix, previewPath, `?access_token=${token}`)
}

const formatPanoDate = (panoDate: string) => {
  const date = parseISO(panoDate)
  return `${format(date, 'MMMM d, yyyy')}`
}

const panoramaPreviewContainer = style({
  width: '100%',
  height: '100%',
  position: 'relative'
})

const panoramaImageStyle = style({
  width: '100%',
  height: '100%',
  objectFit: 'cover'
})
