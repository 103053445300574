import * as React from 'react'
import { classes, style } from 'typestyle'
import { cardStyle } from './SiteCard'
import { Gray10 } from '@traceair/tds'

const SiteCardSkeleton = () => {
  return <div className={classes('site-card-skeleton', cardStyle, skeletonStyle)} />
}

export default SiteCardSkeleton

const skeletonStyle = style({
  background: Gray10
})
