import jwtDecode from 'jwt-decode'
import * as base64 from 'base64-js'
import { inflate } from 'pako'
import { CustomerId, SiteId } from './types'

export type JWTToken = string
type Permission = string

export interface IAcl {
  systemLevel: Permission[]
  customerLevel: { [key: string]: string }
  siteLevel: { [key: string]: string }
}

interface IAccessTokenPayload {
  ACL: IAcl
  user: ICurrentUser
}

export interface ICurrentUser {
  id: string
  email: string
  name?: string
  lastname?: string
  phone?: string
  company?: {
    id: string
    name: string
    type: string
  }
  department?: string
  titleDescription?: string
  lang: string
  title?: string
  createdAt: string
  updatedAt?: string
}

export function decodeJWT(jwt: string): IAccessTokenPayload {
  const accessToken = jwtDecode(jwt) as { payload: IAccessTokenPayload | string; deflated: boolean }
  let payload = accessToken.payload
  if (accessToken.deflated) {
    const deflated = base64.toByteArray(accessToken.payload as string)
    payload = JSON.parse(inflate(deflated, { to: 'string' }))
  }
  return payload as IAccessTokenPayload
}

export function getCustomers(acl: IAcl) {
  return Object.keys(acl.customerLevel)
}

export enum PermissionLevel {
  System,
  Customer,
  Site
}

type PermissionConfig = {
  name: Permission
  level: PermissionLevel
}

export function checkPermissions(
  requiredPermissions: PermissionConfig[],
  userACL: IAcl,
  customerId: string,
  siteId: string
) {
  if (!requiredPermissions) return true

  return requiredPermissions.every(requiredPermission =>
    checkActionPermission(requiredPermission, userACL, customerId, siteId)
  )
}

function checkActionPermission(permission: PermissionConfig, ACL: IAcl, customerId: string, siteId: string) {
  switch (permission.level) {
    case PermissionLevel.System:
      return checkSystemLevelPermission(permission, ACL)
    case PermissionLevel.Customer:
      return checkCustomerLevelPermission(permission, ACL, customerId)
    case PermissionLevel.Site:
      return checkSiteLevelPermission(permission, ACL, customerId, siteId)
  }
}

function checkSystemLevelPermission(permission: PermissionConfig, ACL: IAcl) {
  return ACL.systemLevel.indexOf(permission.name) > -1
}

function checkCustomerLevelPermission(permission: PermissionConfig, ACL: IAcl, customerId: CustomerId) {
  return (
    !!ACL.customerLevel[permission.name] &&
    [customerId, '*'].some(accessIdentifier => ACL.customerLevel[permission.name].includes(accessIdentifier))
  )
}

function checkSiteLevelPermission(permission: PermissionConfig, ACL: IAcl, customerId: CustomerId, siteId: SiteId) {
  return (
    !!ACL.siteLevel[permission.name] &&
    [`${customerId}/${siteId}`, `${customerId}/*`, '*/*'].some(accessIdentifier =>
      ACL.siteLevel[permission.name].includes(accessIdentifier)
    )
  )
}
