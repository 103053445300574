import * as React from 'react'
import { classes, style } from 'typestyle'
import { Blue80, Gray20, White } from '@traceair/tds'
import useAppStore from '../../../AppStore'
import SitePreviewImg from './SitePreviewImg'
import SchedulesSummary from './schedule/SchedulesSummary'
import SiteCardTitle from './SiteCardTitle'
import ContextMenuButton from './context_menu/ContextMenuButton'
import { formatDistanceToNowStrict } from 'date-fns'
import { getDFNSLocale, t7e } from '../../../i18n/i18n'
import { i18nKeys } from '../../../i18n/i18nkeys'
import SiteCardBadges from './SiteCardBadges'
import { CustomerData, SiteData } from '../../../types'
import { CARD_HEIGHT } from '../../../layout-constants'
import { FunctionComponent } from 'react'
import { buildOfficeLink } from '../../../helpers'

interface ISiteCardProps {
  site: SiteData
}

const SiteCard: FunctionComponent<ISiteCardProps> = ({ site }) => {
  const customers = useAppStore(state => state.customers)

  const customer = customers.find((c: CustomerData) => c.id === site.customerId)
  if (!customer) {
    return null
  }

  const { name, hasLotViewer, schedule, latestScan, latestPanos } = site
  const officeAppLink = buildOfficeLink(customer, site)

  const isMilestonesSelected = schedule?.milestones.some(milestone => milestone.isKey)

  return (
    <div className={classes('dashboard-site-card', cardStyle)}>
      <ContextMenuButton customer={customer} site={site} />
      <SiteCardBadges
        isLotViewerEnabled={hasLotViewer}
        formattedDate={
          latestScan
            ? formatDistanceToNowStrict(new Date(latestScan.doneDate), { addSuffix: true, locale: getDFNSLocale() })
            : t7e(i18nKeys.NoScan)
        }
      />
      <SitePreviewImg scan={latestScan} panoramas={latestPanos} officeAppLink={officeAppLink} />
      <SiteCardTitle siteName={name} customerName={customer.name} schedule={schedule} officeAppLink={officeAppLink} />
      {schedule?.latestProgressSnapshot.milestones && isMilestonesSelected && <SiteCardSpacer />}
      {schedule && <SchedulesSummary schedule={schedule} />}
    </div>
  )
}

const SiteCardSpacer = () => <div className={classes('site-card-spacer', siteCardSpacerStyle)} />

const siteCardSpacerStyle = style({
  height: 2,
  width: '100%',
  backgroundColor: Gray20,
  margin: '12px 0 12px 0'
})

export const cardStyle = style({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  background: White,
  borderRadius: 16,
  overflow: 'hidden',
  height: CARD_HEIGHT,
  maxWidth: 480,
  paddingBottom: 16,
  justifyContent: 'flex-end',
  border: `1px solid ${Gray20}`,
  boxShadow:
    '0px 1.751734972000122px 3.130388021469116px 0px rgba(0, 0, 0, 0.04), 0px 9px 25px 0px rgba(0, 0, 0, 0.07)',

  $nest: {
    '&:hover': {
      border: `1px solid ${Blue80}`,
      boxShadow:
        '0px 1.751734972000122px 3.130388021469116px 0px rgba(0, 122, 255, 0.1), 0px 9px 25px 0px rgba(0, 122, 255, 0.1)'
    },
    '&:hover .site-card-context-menu-button': {
      display: 'flex'
    }
  }
})

export default SiteCard
