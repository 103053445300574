import axios from 'axios'
import { sessionIdInterceptor } from './SessionIDInterceptor'
import { getErrorMessage, getToken } from '../helpers'

export type UpsellRequestType = 'flights' | 'panos' | 'lot-viewer' | 'schedules' | 'renewal'

const OngoingProjectsApi = axios.create({
  baseURL: OngoingProjectsAPIBaseUrl
})

OngoingProjectsApi.interceptors.request.use(config => sessionIdInterceptor(config))

export async function getOPPIToken(): Promise<string> {
  try {
    return await getToken(['CONTRACTS_PM_APP_ONGOING_PROJECTS_MANAGE'])
  } catch (error) {
    console.error(error)

    throw error
  }
}

export async function requestUpsell(params: {
  customerId: string
  siteId: string
  props: {
    type: UpsellRequestType
    data: {
      comment?: string
      count?: number
    }
  }
}): Promise<unknown> {
  const { customerId, siteId, props } = params

  try {
    const token = await getOPPIToken()

    return (
      await OngoingProjectsApi.post(`/api/v1/${customerId}/${siteId}/upsells`, props, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    ).data
  } catch (err) {
    console.error(`Failed to call OPPI: ${getErrorMessage(err)}`)

    throw err
  }
}
