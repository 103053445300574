import React from 'react'
import { Window, WindowBody, WindowHeader } from '@traceair/tds'
import WindowCloseButton from './WindowCloseButton'
import { style } from 'typestyle'
import useAppStore from '../../AppStore'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'

interface IToUWindowProps {
  url: string
}

const ToUWindow = ({ url }: IToUWindowProps) => {
  return (
    <div className={ToUWindowStyle}>
      <Window
        className={style({ width: '100%' })}
        header={
          <WindowHeader
            title={t7e(i18nKeys.TermsOfUse)}
            rightCell={<WindowCloseButton onClick={() => useAppStore.getState().actions.hideWindow()} />}
          />
        }
      >
        <WindowBody>
          <iframe
            src={url}
            className={style({ display: 'flex', flexGrow: 1, border: 0, width: '100%', height: '100%' })}
          />
        </WindowBody>
      </Window>
    </div>
  )
}

const ToUWindowStyle = style({
  display: 'flex',
  width: '85vw',
  height: '95vh',
  maxWidth: '600px'
})

export default ToUWindow
