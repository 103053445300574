import * as React from 'react'
import { classes, style } from 'typestyle'
import { White } from '@traceair/tds'
import useAppStore, { AppStoreType } from '../../AppStore'
import HelpButton from '../HelpButton'
import { showOnlyOnTabletsOrMobiles } from '../../responsive-breakpoints'
import AppsNavigationButton from './apps_navigation_bar/AppsNavigationButton'

const { enabled: intercomEnabled } = INTERCOM

const NavigationButtons = () => {
  const user = useAppStore((state: AppStoreType) => state.user)

  if (!user) {
    return
  }

  return (
    <div className={classes('dashboard-navigation-container', navigationContainerStyle)}>
      <AppsNavigationButton />
      {intercomEnabled && (
        <HelpButton className={classes(helpButtonStyle, showOnlyOnTabletsOrMobiles)} iconColor={White} />
      )}
    </div>
  )
}

const navigationContainerStyle = style({
  alignItems: 'center',
  display: 'flex',
  $nest: {
    '&>:not(:first-child)': { marginLeft: 12 }
  }
})

const helpButtonStyle = style({
  backgroundColor: 'transparent'
})

export default NavigationButtons
