import { IAcl } from './jwt-utils'
import { CustomerData, ProjectActivity } from './types'
import { getSortedCustomers } from './components/sidebar/helpers'

const permissionToGoToBackoffice = 'BACKOFFICE_SCAN_WORKFLOW_START'

export const checkIfCanWorkInBackoffice = (acl: IAcl) => {
  return acl.siteLevel[permissionToGoToBackoffice]
}

export function checkIfCanWorkInProjectsManagementApp(systemLevelPermissions: string[]) {
  /**
   * Only checking system-level permissions because it's good enough for determining if a user can work in PM app,
   * as most pages in it only use such permissions.
   *
   * Not checking site-level permissions here, to keep things simple. Agreed upon by Zeta team.
   */
  const PMAPermissions = [
    'BACKOFFICE_CS_APP_PROJECT_SETUP_STATUS_LIST',
    'CONTRACTS_CS_APP_ONGOING_PROJECTS_UI_VIEW_TAB',
    'BACKOFFICE_RECIPIENTS_LIST_MANAGE'
  ]
  const hasAtLeastOnePermission = systemLevelPermissions.some(perm => PMAPermissions.includes(perm))

  // Link is set to an empty string in unsupported environments;
  const hasPMAOnThisEnv = Boolean(ProjectsManagementAppUrl)

  return hasAtLeastOnePermission && hasPMAOnThisEnv
}

export const wait = (t: number) => new Promise(resolve => setTimeout(resolve, t))

export function stringToInt(str?: string): number | undefined {
  if (str === undefined || str === null) return

  const parsed = parseInt(str.trim(), 10)

  if (!isNaN(parsed)) {
    return parsed
  }
}

export function getSortedCustomersWithActiveSites(customers: CustomerData[]): CustomerData[] {
  return getSortedCustomers(
    customers.filter(customer => customer.sites.some(site => site.projectActivity !== ProjectActivity.Archived))
  )
}
