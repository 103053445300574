import { useEffect, useState } from 'react'
import { getLoginService, initLoginService } from './services/LoginService'
import Header from './components/header/Header'
import { classes, style } from 'typestyle'
import * as Sentry from '@sentry/react'
import InspectletUtils from './InspectletUtils'
import track, { initTracker } from './Tracking'
import { endAppLoadMetrics, numericMetrics, startAppLoadMetrics } from './libs/time_metrics/AppLoadMetrics'

import 'navigator.sendbeacon'
import 'performance-polyfill'
import WindowManager from './components/WindowManager'
import useAppStore, { AppStoreType } from './AppStore'
import { CustomerData } from './types'
import WidgetsContainer from './components/widgets/WidgetsContainer'
import MainContainer from './components/sites_grid/MainContainer'
import { getErrorMessage, getUserFilters, loadInitialInfo } from './helpers'
import sitesDataManager from './SitesDataManager'
import { useIntercom } from './hooks'
import { setInitialJwtTokenAndStartUpdateLoop } from './TokenRenewer'

const loadApp = async () => {
  startAppLoadMetrics()

  initLoginService(LoginServiceBaseUrl)

  try {
    const tokenRequests = [
      setInitialJwtTokenAndStartUpdateLoop(servicesPermissions, useAppStore.getState().actions.updateToken),
      setInitialJwtTokenAndStartUpdateLoop(['SITE_VIEW'], useAppStore.getState().actions.setSiteViewToken)
    ]

    await Promise.all(tokenRequests)
  } catch (err) {
    console.error('Failed to get token', getErrorMessage(err))
    return redirectToSigninPage()
  }

  const customers = await loadInitialInfo()
  numericMetrics(customers)

  await getUserFilters()
  endAppLoadMetrics()
}

const App = () => {
  const [dashboardOpenedEventSent, setDashboardOpenedEventSent] = useState(false)

  const user = useAppStore((state: AppStoreType) => state.user)
  const customers = useAppStore((state: AppStoreType) => state.customers)
  const appWindow = useAppStore((state: AppStoreType) => state.window)

  useEffect(() => {
    loadApp()
    sitesDataManager.subscribeToStore()
    return () => {
      sitesDataManager.unsubscribeFromStore()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleSitesContainerScroll)
    return () => window.removeEventListener('scroll', handleSitesContainerScroll)
  }, [])

  useIntercom()

  const handleSitesContainerScroll = async () => {
    const { customersDataLoading, totalSites, sites, actions } = useAppStore.getState()
    if (customersDataLoading || (totalSites !== null && sites.length === totalSites)) {
      return
    }
    const siteListContainerBottomOffset = 1000
    const container = document.documentElement
    const elementRelativeBottom = container.scrollTop + container.clientHeight
    if (elementRelativeBottom > container.scrollHeight - siteListContainerBottomOffset) {
      actions.setLoadedSitesSkipCount(sites.length)
    }
  }

  if (user && SENTRY.enabled) {
    Sentry.setUser({
      id: user.id,
      email: user.email,
      name: `${user.name} ${user.lastname}`
    })
  }

  if (Inspectlet.enabled && user && user.email.includes('traceair.net')) {
    InspectletUtils.init(Inspectlet.id, user)
  }

  if (user && customers.length && !dashboardOpenedEventSent) {
    initTracker(
      user,
      customers.map((c: CustomerData) => c.name)
    )

    track('App is opened')
    setDashboardOpenedEventSent(true)
  }

  if (!user) {
    return null
  }

  return (
    <div className={classes('app-container', appContainerStyle)}>
      <Header />
      <WidgetsContainer />
      <MainContainer />
      {appWindow && <WindowManager window={appWindow} />}
    </div>
  )
}

export default App

function redirectToSigninPage() {
  const loginServiceUrl = getLoginService().loginService.defaults.baseURL
  if (!loginServiceUrl) {
    return console.error('Login service url not specified')
  }
  window.location.href = `${SignInPageUrl}?referer=${encodeURIComponent(
    window.location.href
  )}&loginServiceBaseUrl=${encodeURIComponent(loginServiceUrl)}`
}

const appContainerStyle = style({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
})
