import React from 'react'
import track from '../../Tracking'
import { Button } from '@traceair/tds'
import ToUWindow from './ToUWindow'
import useAppStore from '../../AppStore'
import { getLoginService } from '../../services/LoginService'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'

const ToUButton = () => {
  const [url, setUrl] = React.useState<string>('')
  React.useEffect(() => {
    const fetchTermsOfUse = async () => {
      const termsOfUse = await getLoginService().getTermsOfUse()
      const { url } = termsOfUse
      setUrl(url)
    }
    fetchTermsOfUse()
  }, [])

  const handleShowToUClick = () => {
    if (!url) {
      return
    }
    track('Click on show Terms of Use')
    useAppStore.getState().actions.showWindow(<ToUWindow url={url} />)
  }

  return (
    <Button
      styleType='tertiary'
      disabled={!url}
      className={'ToU-button'}
      text={t7e(i18nKeys.TermsOfUse)}
      onClick={handleShowToUClick}
    />
  )
}

export default ToUButton
