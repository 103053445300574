import React, { useMemo, useRef, useState } from 'react'
import ArrowButton from './ArrowButton'
import { Black, colorToRgba, White } from '@traceair/tds'
import { classes, style } from 'typestyle'
import track from '../../../../Tracking'
import { debounce } from '../../../../libs/debounce'

interface CarouselProps {
  children: React.ReactNode
}

const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [hovered, setHovered] = useState(false)
  const carouselRef = useRef<HTMLDivElement>(null)

  const goToSlide = (index: number) => {
    if (!carouselRef.current) return
    carouselRef.current.scrollTo({
      left: index * carouselRef.current.offsetWidth,
      behavior: 'smooth'
    })
  }

  const handlePrev = () => {
    track('Previous site preview Click')
    goToSlide(activeIndex - 1)
  }
  const handleNext = () => {
    track('Next site preview Click')
    goToSlide(activeIndex + 1)
  }

  const validChildren = React.Children.toArray(children).filter(child => React.isValidElement(child))

  const maxDotsToShow = 5
  const totalSlides = validChildren.length
  const visibleDotIndices = getDotIndices(activeIndex, totalSlides, maxDotsToShow)

  const handleScroll = React.useCallback(() => {
    if (carouselRef.current) {
      const scrollLeft = carouselRef.current.scrollLeft
      const slideWidth = carouselRef.current.offsetWidth
      const newActiveIndex = Math.round(scrollLeft / slideWidth)
      setActiveIndex(newActiveIndex)
    }
  }, [])

  const debouncedHandleScroll = useMemo(() => debounce(handleScroll, 100), [handleScroll])

  return (
    <div
      className={carouselContainerStyle}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className={carouselStyle} onScroll={debouncedHandleScroll} ref={carouselRef}>
        <div className={classes(carouselInnerStyle)}>
          {React.Children.map(validChildren, (child, index) => {
            return (
              <div key={index} className={slideStyle}>
                {child}
              </div>
            )
          })}
        </div>
        {totalSlides > 1 && (
          <>
            <ArrowButton
              className='carousel-prev-button'
              direction='left'
              onClick={handlePrev}
              visible={activeIndex !== 0 && hovered}
            />
            <ArrowButton
              className='carousel-next-button'
              direction='right'
              onClick={handleNext}
              visible={activeIndex !== React.Children.count(children) - 1 && hovered}
            />
          </>
        )}
        {totalSlides > 1 && (
          <div className={classes(indicatorStyle, 'carousel-nav-dots-container')}>
            {visibleDotIndices.map(index => (
              <button
                key={index}
                className={classes(
                  'carousel-nav-dot',
                  index === activeIndex && 'carousel-nav-dot-active',
                  dotStyle(index, activeIndex, visibleDotIndices, totalSlides)
                )}
                onClick={e => {
                  track('Slide selected')
                  e && e.stopPropagation()
                  goToSlide(index)
                }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Carousel

const getDotIndices = (activeIndex: number, totalSlides: number, maxDots: number) => {
  if (totalSlides <= maxDots) {
    return Array.from({ length: totalSlides }, (_, i) => i)
  }

  let start = activeIndex - Math.floor(maxDots / 2)
  start = Math.max(start, 0)
  start = Math.min(start, totalSlides - maxDots)
  return Array.from({ length: maxDots }, (_, i) => start + i)
}

const carouselContainerStyle = style({
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
  width: '100%',
  borderRadius: 8
})

const carouselStyle = style({
  overflowX: 'scroll',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',
  scrollSnapType: 'x mandatory',
  height: '100%',
  $nest: {
    '&:before': {
      background: `linear-gradient(180deg, ${colorToRgba(White, 0)} 0%, ${colorToRgba(Black, 0.3)} 100%)`,
      bottom: 0,
      content: '""',
      height: 48,
      left: 0,
      position: 'absolute',
      right: 0,
      zIndex: 1
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
})

const carouselInnerStyle = style({
  display: 'flex',
  height: '100%',
  width: '100%',
  $nest: {
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center'
    },
    '& > *': {
      scrollSnapAlign: 'center'
    }
  }
})

const slideStyle = style({
  minWidth: '100%'
})

const indicatorStyle = style({
  alignItems: 'center',
  position: 'absolute',
  bottom: '10px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  zIndex: 2
})

const dotStyle = (index: number, activeIndex: number, visibleDotIndices: number[], totalSlides: number) => {
  const isTotalEdgeDot = index === 0 || index === totalSlides - 1
  const isActiveDot = index === activeIndex
  const isAdjacentToMiddle = Math.abs(index - visibleDotIndices[2]) === 1
  const inMiddleOfVisibleDots = visibleDotIndices[2] === index

  // Determine the size of the dot based on the rules provided
  const isLargeDot = isActiveDot || isAdjacentToMiddle || isTotalEdgeDot || inMiddleOfVisibleDots
  const isSmallDot = !isLargeDot

  return style({
    border: 'none',
    borderRadius: '50%',
    margin: '0 4px',
    cursor: 'pointer',
    backgroundColor: White,
    opacity: isActiveDot ? 1 : 0.5,
    width: isSmallDot ? '4px' : '8px',
    height: isSmallDot ? '4px' : '8px'
  })
}
